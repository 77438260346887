import React from 'react';
import { Prompt, withRouter } from 'react-router';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Grid, Typography, Button } from '@material-ui/core';

import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import * as Sentry from '@sentry/react';

import Loading from '../utils/Loading';
import AppraisalDetailsHeader from './appraisalDetails/AppraisalDetailsHeader';
import AppraisalDetailsCarousel from './appraisalDetails/AppraisalDetailsCarousel';
import AppraisalDetailsSidebar from './appraisalDetails/AppraisalDetailsSidebar';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
function AppraisalDetails(props) {
  const ctx = React.useContext(GlobalContext);
  const cls = useStyles();

  const appraisalId = props.match.params.id;

  let customerId = parseInt(
    ctx.customerOverride ||
      (ctx.userProfile && ctx.userProfile[`https://hasura.io/jwt/claims`]
        ? ctx.userProfile[`https://hasura.io/jwt/claims`][`x-hasura-customer-id`]
        : 0)
  );

  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [tookanImages, setTookanImages] = React.useState([]);

  const [access, setAccess] = React.useState({});

  React.useEffect(() => {
    setCustomerAccess(customerId);
  }, [customerId]);

  // Get the customer config and set the customer's access
  const setCustomerAccess = async id => {
    if (id && ctx.apolloClient && ctx.apolloClient.query) {
      let res = await ctx.apolloClient.query({
        query: GET_CUSTOMER_CONFIG,
        variables: { id: id },
        onError: err => {
          Sentry.captureException(err);
          console.error(`Error getting customer config:`, err);
        },
      });

      if (res.data && res.data.customers && res.data.customers.length > 0) {
        const config = res.data.customers[0].config;
        const appraisalsAccess = config && config.appraisals ? config.appraisals : {};
        setAccess(appraisalsAccess);
      }
    }
  };

  const applySorting = dataset => {
    if (dataset && dataset.length > 0)
      return dataset.sort((a, b) => {
        const aTime = a.pickup_time || a.ready_by;
        const bTime = b.pickup_time || b.ready_by;
        if (!aTime) return 1;
        if (aTime > bTime) return -1;
        if (aTime < bTime) return 1;
        return 0;
      });
    else return [];
  };

  const applyFiltering = dataset => {
    if (dataset && dataset.length > 0) {
      let newDataset = [...dataset];
      newDataset = newDataset.filter(move => {
        let useMove = true;

        if (
          move.status !== `pickup successful` &&
          move.status !== `delivery started` &&
          move.status !== `delivery arrived` &&
          move.status !== `delivery successful`
        )
          useMove = false;

        if (move.cancel_status && move.cancel_status.includes(`canceled`)) useMove = false;

        if (move.consumer_type === `loaner`) useMove = false;

        return useMove;
      });
      return newDataset;
    } else return [];
  };

  return (
    <>
      {ctx && ctx.userIsAuthenticated() && access.active ? (
        <Subscription
          subscription={GET_APPRAISAL_BY_ID}
          variables={{ id: appraisalId, customer_id: customerId }}
          onError={error => {
            console.error(error);
            Sentry.captureException(error);
          }}
        >
          {({ loading, data }) => {
            if (loading) return <Loading fixed />;
            if (data && data.appraisals && data.appraisals.length > 0) {
              let appraisal = data.appraisals[0];
              if (appraisal.moves && appraisal.moves.length > 0) {
                let sortedMoves = applySorting(appraisal.moves);
                let filteredMoves = applyFiltering(sortedMoves);
                if (filteredMoves && filteredMoves.length > 0) {
                  appraisal.vehicle_year = filteredMoves[0].vehicle_year;
                  appraisal.vehicle_make = filteredMoves[0].vehicle_make;
                  appraisal.vehicle_model = filteredMoves[0].vehicle_model;
                  appraisal.vehicle_color = filteredMoves[0].vehicle_color;
                  appraisal.vehicle_odometer =
                    filteredMoves[0].vehicle_odometer && !isNaN(parseInt(filteredMoves[0].vehicle_odometer))
                      ? parseInt(filteredMoves[0].vehicle_odometer)
                      : 0;
                  appraisal.consumer_name = filteredMoves[0].consumer_name;
                  appraisal.consumer_phone = filteredMoves[0].consumer_phone;
                  appraisal.moves = filteredMoves;
                }
              }
              let apiVehicle = appraisal.vehicle_data ? appraisal.vehicle_data : {};
              // let moves = appraisal.moves && appraisal.moves.length > 0 ? appraisal.moves : null;
              log && console.log(`Appraisal Data:`, appraisal);

              return (
                <>
                  <Prompt
                    when={unsavedChanges}
                    message='You are attempting to leave the page with unsaved changes. Are you sure you want to do this?'
                  />
                  <div className={cls.root}>
                    <Grid container justify='space-between' alignItems='flex-start' wrap='nowrap'>
                      <Grid className={cls.gridLeft} item xl={9} lg={8} md={7} sm={6} xs={12}>
                        <div className={cls.base}>
                          <div className={cls.header}>
                            <AppraisalDetailsHeader appraisal={appraisal} />
                          </div>
                          <div className={cls.body}>
                            <Container maxWidth='md'>
                              <AppraisalDetailsCarousel
                                appraisal={appraisal}
                                tookanImages={tookanImages}
                                setTookanImages={setTookanImages}
                              />
                            </Container>
                          </div>
                        </div>
                      </Grid>
                      <Grid className={cls.gridRight} item xl={3} lg={4} md={5} sm={6} xs={12}>
                        <AppraisalDetailsSidebar
                          appraisal={appraisal}
                          vehicle={apiVehicle}
                          unsavedChanges={unsavedChanges}
                          setUnsavedChanges={setUnsavedChanges}
                          tookanImages={tookanImages}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </>
              );
            } else
              return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO APPRAISAL RECORD FOUND</Typography>
                </div>
              );
          }}
        </Subscription>
      ) : (
        <div className={cls.restricted}>
          <Container maxWidth='md'>
            <Typography className={cls.restrictedText}>
              Welcome to the new HopDrive appraisal feature. This revolutionary tool will turn your Service Lane into an
              Appraisal Drive and help you buy more cars from your customers! If you are interested in seeing a demo,
              please email anthony@hopdrive.com to set up some time.
            </Typography>
            <a href='mailto:anthony@hopdrive.com?cc=sales@hopdrive.com&subject=Appraisals%20-%20Demo%20Request'>
              <Button className={cls.restrictedButton} color='primary' variant='contained' size='large'>
                Request Demo
              </Button>
            </a>
          </Container>
        </div>
      )}
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  gridLeft: {
    position: 'relative',
  },
  gridRight: {
    position: 'relative',
  },
  base: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: '64px',
  },
  header: {
    zIndex: 10,
    position: 'relative',
  },
  body: {
    position: 'relative',
    height: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflow: 'overlay',
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  restricted: {
    width: '100%',
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  restrictedText: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 400,
  },
  restrictedButton: {
    marginTop: theme.spacing(3),
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////
const GET_APPRAISAL_BY_ID = gql`
  subscription get_appraisal_by_id($id: bigint!, $customer_id: bigint!) {
    appraisals(where: { id: { _eq: $id }, customer_id: { _eq: $customer_id } }) {
      id
      customer_id
      status
      vin
      vehicle_data
      condition
      trim_overrides
      mileage_override
      value_type
      value_total
      value_adjustment
      vehicle_notes
      favorite
      last_fetch_time_utc
      last_save_time_utc
      appraiser_name
      appraiser_credentials
      appraiser_contact
      appraiser_notes
      appraiser_expire_time_utc
      customer {
        id
        name
        phone
      }
      moves(
        limit: 50
        where: { active: { _eq: 1 }, move_type: { _eq: "drive" }, customer_id: { _eq: $customer_id } }
        order_by: { pickup_time: desc_nulls_last, ready_by: desc_nulls_last, id: desc_nulls_last }
      ) {
        id
        customer_id
        pickup_stop_id
        delivery_stop_id
        status
        pickup_time
        ready_by
        vehicle_year
        vehicle_make
        vehicle_model
        vehicle_color
        vehicle_odometer
        consumer_name
        consumer_phone
        lane {
          id
          description
        }
      }
    }
  }
`;

const GET_CUSTOMER_CONFIG = gql`
  query get_customer_config($id: bigint!) {
    customers(where: { id: { _eq: $id } }) {
      id
      name
      config
    }
  }
`;

//////////////////////////////////////// EXPORT ////////////////////////////////////////
export default withRouter(AppraisalDetails);
