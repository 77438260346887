import React, { Component } from "react";
import "./index.css";
import { GlobalContext } from "./global-context";
import Layout from "./components/Layout";
import ApolloClient from "apollo-client"
import { WebSocketLink } from "apollo-link-ws"
import { HttpLink } from "apollo-link-http"
import { split } from "apollo-link"
import { getMainDefinition } from "apollo-utilities"
import { InMemoryCache } from "apollo-cache-inmemory"
import { setContext } from "apollo-link-context"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { withWidth } from '@material-ui/core';
import { ApolloProvider } from "react-apollo"
import * as Sentry from "@sentry/react";
import { withAuth0 } from "@auth0/auth0-react";
import Loading from "./components/utils/Loading";
import _sdk from '@hopdrive/sdk';

let log = false;

const theme = createMuiTheme({
  palette: {
    primary: { light: `#f88277`, main: `#f44232`, dark: `#e91f0c`, contrastText: `#fff` },
    secondary: { light: `#7591bd`, main: `#496797`, dark: `#2b3d59`, contrastText: `#fff` },
    info: { light: `#64b5f6`, main: `#2080ff`, dark: `#1976d2`, contrastText: `#fff` },
    error: { light: `#ffa0a8`, main: `#ff2050`, dark: `#d41025`, contrastText: `#fff` },
    warning: { light: `#ffb74d`, main: `#ffa040`, dark: `#f57c00`, contrastText: `#fff` },
    success: { light: `#81c784`, main: `#20c820`, dark: `#388e3c`, contrastText: `#fff` },

    text: {
      primary: `#323232`,
      secondary: `#32323296`,
      tertiary: `#32323272`,
      disabled: `#32323272`,
      hint: `#32323272`,
      contrast: `#fff`,
    },

    divider: `#32323224`,
    softDivider: `#32323216`,
    hardDivider: `#32323232`,
    border: `#ddd`,

    fade: {
      "c8": `#323232c8`,
      "a0": `#323232a0`,
      "80": `#32323280`,
      "60": `#32323260`,
      "40": `#32323240`,
      "20": `#32323220`,
    },

    action: {
      hover: `#32323212`,
      selected: `#32323224`,
      focus: `#32323236`,
      active: `#323232a0`,
      disabled: `#32323264`,
      disabledBackground: `#32323236`,
    },

    background: {
      default: `#fafafa`,
      light: `#f8f8f8`,
      main: `#f4f4f4`,
      dark: `#f0f0f0`,
    },
  },
  borderRadius: '4px',
  paperRadius: '8px',
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth0: props.auth0,
      // The below function 'userIsAuthenticated()' determines if a user is both logged in through Auth0 and has an Apollo Client initialized
      // The 'auth0' prop has a boolean 'isAuthenticated' that identifies whether or not the current user is logged in
      // Additionally, we set our own boolean 'apolloInitialized' in state when a user sets up a new Apollo Client with their JWT
      // If both of these values are true, then the user is deemed 'authenticated'
      userIsAuthenticated: () => this.state.auth0.isAuthenticated && this.state.apolloInitialized ? true : false,
      apolloInitialized: false,
      notificationShow: false,
      notificationVariant: '',
      notificationMessage: '',
      handleNotifications: (show, variant = this.state.notificationVariant, message = this.state.notificationMessage) => this.setState(
        {
          notificationShow: show,
          notificationVariant: variant,
          notificationMessage: message
        }
      ),
      userProfile: {},
      userToken: "",
      customerOverride: null,
      // moves: [],
      redirectPath: null,
      logout: () => {
        if (log) console.log("running logout function")
        props.auth0.logout({
          returnTo:
            !process.env.NODE_ENV || process.env.NODE_ENV === "development"
              ? `http://localhost:8888/login`
              : process.env.REACT_APP_AUTH_CALLBACK,
        });
        this.setState({ userProfile: null, userToken: "", apolloInitialized: false, apolloClient: {} });
      },
      setUserAuth: (profile, token) => {
        if (log) console.log("profile", profile, "and token", token);
        this.setState({ userProfile: profile });
        this.setState({ userToken: token });
        // If a user is an admin, set their selected to customer from localStorage or their app metadata
        if (profile["https://hasura.io/jwt/claims"]['x-hasura-allowed-roles'].includes('admin') || profile["https://hasura.io/jwt/claims"]['x-hasura-allowed-roles'].includes('dealer-admin')) {
          this.setState({ customerOverride: localStorage.getItem('selectedCustomerId') ? localStorage.getItem('selectedCustomerId') : profile["https://hasura.io/jwt/claims"]['x-hasura-customer-id'] });
        } else {
          let customers = profile["https://hasura.io/jwt/claims"]["x-hasura-allowed-customers"]
          if (!customers) customers = []
          else customers = JSON.parse(customers.replace("{", "[").replace("}", "]"));
          if (customers.length > 1) this.setState({ customerOverride: localStorage.getItem('selectedCustomerId') ? localStorage.getItem('selectedCustomerId') : profile["https://hasura.io/jwt/claims"]['x-hasura-customer-id'] });
        }
      },
      setSelectedCustomerId: (id) => this.setState({ customerOverride: id }),
      apolloClient: {},
      setupApollo: (token) => {
        if (log) console.log('Setting up the Apollo...')
        const authLink = setContext(async (_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          }
        }),
          wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL,
          httpurl = process.env.REACT_APP_GRAPHQL_URL,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                return {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query)
              return (
                kind === "OperationDefinition" && operation === "subscription"
              )
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
            defaultOptions: {
              query: {
                fetchPolicy: 'network-only'
              }
            },
            connectToDevTools: true
          })
        if (log) console.log('Apollo Client Initialized! ', client)
        _sdk.configure({apollo_client: client})
        this.setState({ apolloClient: client, apolloInitialized: true, sdk: _sdk });
      },
      theme: theme,
      width: props.width,
    };
  }

  // getMoveSubscriptionGQL = () => {
  //   let moveStartDateTime = moment().subtract(1, 'days').format()
  //   let moveUpdatedDateTime = moment().subtract(1, 'days').format()
  //   let maxMoveId = 0
  //   try {
  //     moveStartDateTime = this.state.moves[this.state.moves.length - 1].pickup_time
  //   } catch { }
  //   try {
  //     moveUpdatedDateTime = this.state.moves[this.state.moves.length - 1].updatedat
  //   } catch { }
  //   try {
  //     maxMoveId = Math.max.apply(Math, this.state.moves.map(function (o) { return o.id }))
  //     if (maxMoveId === '-Infinity') maxMoveId = 0
  //   } catch { }
  //   if (log) console.log(`Move subscription gql built using (${maxMoveId}, ${moveStartDateTime}, ${moveUpdatedDateTime})`)
  //   return subscriptions.moves(maxMoveId, moveStartDateTime, moveUpdatedDateTime)
  // }

  // handleMoveSubscription = (opts) => {
  //   if (opts.subscriptionData.data.moves.length > 0) {
  //     if (log) console.log(`${opts.subscriptionData.data.moves.length} moves appended to global.moves`)
  //     const newMoves = this.state.moves.concat(opts.subscriptionData.data.moves)
  //     if (log) console.log('Moves: ', newMoves)
  //     this.setState({ moves: newMoves });
  //   }
  // }
  buildSentryUserObj = userProfile => ({
    email: userProfile.email,
    name: userProfile.name,
    nickname: userProfile.nickname,
    user: userProfile['https://hasura.io/jwt/claims']['x-hasura-user-id'],
    role: userProfile['https://hasura.io/jwt/claims']['x-hasura-default-role'],
    allowed_roles: userProfile['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
    selected_regions: userProfile['https://hasura.io/jwt/claims']['x-hasura-selected-regions'],
    allowed_regions: userProfile['https://hasura.io/jwt/claims']['x-hasura-allowed-regions'],
  })

  // When the Auth0 provider updates with a new auth0 object, check against the current obj
  // If the new object is different, then update the global state
  componentWillReceiveProps = (nextProps) => {
    if (JSON.stringify({ ...nextProps.auth0 }) !== JSON.stringify({ ...this.state.auth0 }))
      this.setState({ auth0: nextProps.auth0 });
  };

  render() {
    if (this.state.auth0.isLoading) return <Loading />
    else if (this.state.userIsAuthenticated() && this.state.userProfile && this.state.apolloClient) {
      Sentry.setContext("user", this.buildSentryUserObj(this.state.auth0.user));
      if (log) console.log('Rendering with ApolloProvider now...')
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <ApolloProvider client={this.state.apolloClient}>
              <Layout />
            </ApolloProvider>
          </GlobalContext.Provider>
        </MuiThemeProvider>
      )
    } else {
      if (log) console.log('Rendering without ApolloProvider until user is logged in.')
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <Layout />
          </GlobalContext.Provider>
        </MuiThemeProvider>
      )
    }
  }
}

export default withWidth()(withAuth0(App));
