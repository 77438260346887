import React from 'react';
import { withRouter } from 'react-router';
import StandardTile from './StandardTile';
import ConsumerTile from './ConsumerTile';
import LoanerTile from './LoanerTile';

function DashboardTile(props) {
  const { move } = props;

  if (move && move.consumer_pickup && move.movesByReturnRideId && move.movesByReturnRideId.length > 0 && move.movesByReturnRideId[0].tags != null && move.movesByReturnRideId[0].tags.includes('loaner')) {
    //Concierge + loaner (consumer car inbound for service)
    return <ConsumerTile move={move} loaner={move.moveByReturnRideId} />
  } else if (move && move.consumer_pickup && !move.moveByReturnRideId && move.tags != null && !move.tags.includes('loaner')) {
    //Concierge (no loaner)
    return <ConsumerTile move={move} />
  } else if (move && !move.consumer_pickup && !move.moveByReturnRideId && !move.chargeable && move.tags != null && move.tags.includes('loaner')) {
    //Single loaner
    return <LoanerTile move={move} />
  } else if (move && move.consumer_pickup && !move.chargeable && move.tags != null && move.tags.includes('loaner')) {
    //Concierge + loaner (loaner being picked up from consumer)
    return <LoanerTile move={move} />
  } else if (move && !move.consumer_pickup && move.movesByReturnRideId && move.movesByReturnRideId.length > 0 && move.chargeable && move.movesByReturnRideId[0].consumer_pickup && move.tags != null && !move.tags.includes('loaner')) {
    //Concierge + loaner (consumer car being returned to consumer)
    return <ConsumerTile move={move} />
  } else {
    //Standard move
    return <StandardTile move={move} />
  }

  return <></>
}

export default withRouter(DashboardTile);