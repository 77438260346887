import React from 'react';
import { makeStyles, Typography, Checkbox, Tooltip, IconButton, Icon } from '@material-ui/core';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function DashboardFilter(props) {
  const cls = useStyles();

  const { vehicles, handleClearFilters, tags, setTags } = props;

  let filter = [];

  const getFilters = () => {
    if (vehicles && vehicles.length > 0) {
      let statuses = [`Viewed`, `Not Viewed`];
      let makes = [];
      let models = [];
      let years = [];

      makes = vehicles
        .map(vehicle => vehicle.move.vehicle_make)
        .filter((make, i) => make !== null && make !== ``)
        .sort();
      makes = makes.filter((make, i) => makes.indexOf(make) === i);

      models = vehicles
        .map(vehicle => vehicle.move.vehicle_model)
        .filter((model, i) => model !== null && model !== ``)
        .sort();
      models = models.filter((model, i) => models.indexOf(model) === i);

      years = vehicles
        .map(vehicle => vehicle.move.vehicle_year)
        .filter((year, i) => year !== null && year !== ``)
        .sort();
      years = years.filter((year, i) => years.indexOf(year) === i);

      log && console.log(`Statuses`, statuses);
      log && console.log(`Makes`, makes);
      log && console.log(`Models`, models);
      log && console.log(`Years`, years);

      return { statuses: statuses, makes: makes, models: models, years: years };
    } else return null;
  };

  filter = getFilters();

  const handleTags = (name, str) => event => {
    if (str && str !== null) {
      let newTags = [...tags[name]];
      if (newTags.length > 0 && newTags.includes(str)) {
        let strIndex = newTags.indexOf(str);
        if (strIndex !== -1) {
          newTags.splice(strIndex, 1);
          setTags[name]([...newTags]);
        }
      } else {
        newTags.push(str);
        setTags[name]([...newTags]);
      }
    }
  };

  return (
    <>
      <div className={cls.paper}>
        <div className={cls.clear}>
          <Tooltip title='Clear Filters'>
            <IconButton onClick={handleClearFilters}>
              <Icon>cached</Icon>
            </IconButton>
          </Tooltip>
        </div>

        <Typography className={cls.head}>Filters</Typography>

        {filter && filter.statuses.length > 0 ? (
          <>
            <Typography className={cls.sub}>Status</Typography>
            {filter.statuses.map((item, i) => (
              <div key={`filter-status-${i}`} className={cls.option}>
                <Checkbox
                  className={cls.optionCheck}
                  color='primary'
                  checked={tags.status.includes(item)}
                  onChange={handleTags(`status`, item)}
                />
                <Typography className={cls.optionTxt}>{item}</Typography>
              </div>
            ))}
          </>
        ) : null}

        {filter && filter.makes.length > 0 ? (
          <>
            <Typography className={cls.sub}>Make</Typography>
            {filter.makes.map((item, i) => (
              <div key={`filter-make-${i}`} className={cls.option}>
                <Checkbox
                  className={cls.optionCheck}
                  color='primary'
                  checked={tags.make.includes(item)}
                  onChange={handleTags(`make`, item)}
                />
                <Typography className={cls.optionTxt}>{item}</Typography>
              </div>
            ))}
          </>
        ) : null}

        {filter && filter.models.length > 0 ? (
          <>
            <Typography className={cls.sub}>Model</Typography>
            {filter.models.map((item, i) => (
              <div key={`filter-model-${i}`} className={cls.option}>
                <Checkbox
                  className={cls.optionCheck}
                  color='primary'
                  checked={tags.model.includes(item)}
                  onChange={handleTags(`model`, item)}
                />
                <Typography className={cls.optionTxt}>{item}</Typography>
              </div>
            ))}
          </>
        ) : null}

        {filter && filter.years.length > 0 ? (
          <>
            <Typography className={cls.sub}>Year</Typography>
            {filter.years.map((item, i) => (
              <div key={`filter-year-${i}`} className={cls.option}>
                <Checkbox
                  className={cls.optionCheck}
                  color='primary'
                  checked={tags.year.includes(item)}
                  onChange={handleTags(`year`, item)}
                />
                <Typography className={cls.optionTxt}>{item}</Typography>
              </div>
            ))}
          </>
        ) : null}

        {!filter ? (
          <>
            <Typography className={cls.notFound}>No filters found.</Typography>
          </>
        ) : null}
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '240px',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      fontSize: 18,
    },
  },
  clear: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
  },
  sub: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 600,
  },
  option: {
    display: 'block',
  },
  optionCheck: {
    display: 'inline-block',
    verticalAlign: 'top',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  optionTxt: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
  },
  notFound: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 500,
  },
}));
