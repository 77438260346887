import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Grid, Typography, Button, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import Divide from '../reusable/Divide';
import AutoRefresh from '../reusable/AutoRefresh';

import gql from "graphql-tag";
import { Query } from "react-apollo";
import * as Sentry from "@sentry/react";

import Loading from '../utils/Loading';
import DashboardFilter from './dashboard/DashboardFilter';
import DashboardGrid from './dashboard/DashboardGrid';
import DashboardGridByCustomer from './dashboard/DashboardGridByCustomer'
import Login from './Login';

const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  contain: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
  },
  filter: {
    verticalAlign: 'top',
    position: 'relative',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  grid: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  head: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.125),
      marginRight: theme.spacing(3),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1.25),
      marginRight: theme.spacing(2),
      fontSize: '18px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  actionSearch: {
    minWidth: '160px',
    '& .MuiOutlinedInput-root': {
      height: '42px',
    },
  },
  actionSort: {
    minWidth: '160px',
    '& .MuiOutlinedInput-root': {
      height: '42px',
    },
  },
  actionBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// TOOLS //////////
const hours = 72;
const timestamp = process.env.REACT_APP_ENV === `production` ? moment.utc(moment().subtract(hours, `hours`)).format() : moment.utc(moment().subtract(1, `year`)).format();

////////// COMPONENT //////////
export default function Dashboard(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [search, setSearch] = useState(``);
  const [sort, setSort] = useState(`old`);
  const [statusTags, setStatusTags] = useState([]);
  const [makeTags, setMakeTags] = useState([]);
  const [yearTags, setYearTags] = useState([]);
  const [colorTags, setColorTags] = useState([]);
  const [conciergeView, setConciergeView] = useState(true);

  useEffect(() => {
    log && console.log(`Dashboard loading...`)
    if (props.location && props.location.state && props.location.state.insertedMove) {
      log && console.log(`Detected route to this page from move planner. Auto refreshing to show new move...`, props.location.state.insertedMove)
      doRefetch()
    }
  }, []) 

  let _refetch = null
  const doRefetch = () => {
    if (_refetch) {
      log && console.log(`Performing refresh of data...`)
      _refetch()
    } else {
      log && console.log(`Tried performing refresh of data but _refetch is not set`)
    }
  }

  let moves = [];

  const handleSearch = (event) => {
    // log && console.log(`Search Input:`, event.target.value);
    setSearch(event.target.value.toLowerCase());
  }
  const handleSort = (event) => {
    // log && console.log(`Sort Input:`, event.target.value);
    setSort(event.target.value);
  }
  const handleClearFilters = () => {
    setSearch(``);
    setSort(`old`);
    setStatusTags([]);
    setMakeTags([]);
    setYearTags([]);
    setColorTags([]);
  }

  const getFixedMoves = (movesData) => {
    let sortedMoves;

    if (sort === `new`) sortedMoves = movesData.sort((a, b) => moment(b.pickup_time).unix() - moment(a.pickup_time).unix())
    else if (sort === `old`) sortedMoves = movesData.sort((a, b) => moment(a.pickup_time).unix() - moment(b.pickup_time).unix())
    else if (sort === `status`) sortedMoves = movesData.sort((a, b) => {
      let valA = a.cancel_status && a.cancel_status !== null ? `aaa${a.cancel_status.toLowerCase()}` : (a.status && a.status !== null ? a.status.toLowerCase() : `zzz`);
      let valB = b.cancel_status && b.cancel_status !== null ? `aaa${b.cancel_status.toLowerCase()}` : (b.status && b.status !== null ? b.status.toLowerCase() : `zzz`);
      return (valA > valB) ? 1 : (valA < valB) ? -1 : 0;
    });
    else if (sort === `make`) sortedMoves = movesData.sort((a, b) => {
      let valA = a.vehicle_make && a.vehicle_make !== null ? a.vehicle_make.toLowerCase() : `zzz`;
      let valB = b.vehicle_make && b.vehicle_make !== null ? b.vehicle_make.toLowerCase() : `zzz`;
      return (valA > valB) ? 1 : (valA < valB) ? -1 : 0;
    });
    else if (sort === `model`) sortedMoves = movesData.sort((a, b) => {
      let valA = a.vehicle_model && a.vehicle_model !== null ? a.vehicle_model.toLowerCase() : `zzz`;
      let valB = b.vehicle_model && b.vehicle_model !== null ? b.vehicle_model.toLowerCase() : `zzz`;
      return (valA > valB) ? 1 : (valA < valB) ? -1 : 0;
    });
    else if (sort === `year`) sortedMoves = movesData.sort((a, b) => {
      let valA = a.vehicle_year && a.vehicle_year !== null ? a.vehicle_year : `0`;
      let valB = b.vehicle_year && b.vehicle_year !== null ? b.vehicle_year : `0`;
      return (valA < valB) ? 1 : (valA > valB) ? -1 : 0;
    });
    else if (sort === `color`) sortedMoves = movesData.sort((a, b) => {
      let valA = a.vehicle_color && a.vehicle_color !== null ? a.vehicle_color.toLowerCase() : `zzz`;
      let valB = b.vehicle_color && b.vehicle_color !== null ? b.vehicle_color.toLowerCase() : `zzz`;
      return (valA > valB) ? 1 : (valA < valB) ? -1 : 0;
    });

    let filterResults = sortedMoves;
    if (statusTags && statusTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.cancel_status && statusTags.includes(`Cancelled`)) return true;
        if (!move.cancel_status && (!move.status || (move.status && move.status === `dispatched`)) && statusTags.includes(`Planning`)) return true;
        if (!move.cancel_status && move.status && move.status.includes(`pickup`) && statusTags.includes(`Pickup`)) return true;
        if (!move.cancel_status && move.status && move.status.includes(`delivery`) && !move.status.includes(`delivery successful`) && statusTags.includes(`Delivery`)) return true;
        if (!move.cancel_status && move.status && move.status === (`delivery successful`) && statusTags.includes(`Completed`)) return true;
        return false;
      });
    }
    if (makeTags && makeTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.vehicle_make && makeTags.includes(move.vehicle_make)) return true;
        return false;
      });
    }
    if (yearTags && yearTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.vehicle_year && yearTags.includes(move.vehicle_year)) return true;
        return false;
      });
    }
    if (colorTags && colorTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.vehicle_color && colorTags.includes(move.vehicle_color)) return true;
        return false;
      });
    }
    // log && console.log(`Filter Results`, filterResults);

    let searchResults = filterResults;
    if (search && search.length > 0) searchResults = searchResults.filter(move => 
      (move.id && (move.id + ``).toLocaleLowerCase().includes(search)) ||
      (move.lane ? move.lane.description && move.lane.description.toLocaleLowerCase().includes(search) : false) ||
      (move.reference_num && move.reference_num.toLocaleLowerCase().includes(search)) ||
      (move.vehicle_stock && move.vehicle_stock.toLocaleLowerCase().includes(search)) ||
      (move.vehicle_vin && move.vehicle_vin.toLocaleLowerCase().includes(search)) ||
      (move.vehicle_make && move.vehicle_make.toLocaleLowerCase().includes(search)) ||
      (move.vehicle_model && move.vehicle_model.toLocaleLowerCase().includes(search)) ||
      (move.vehicle_year && move.vehicle_year.toLocaleLowerCase().includes(search)) ||
      (move.vehicle_color && move.vehicle_color.toLocaleLowerCase().includes(search)) ||
      (move.consumer_name && move.consumer_name.toLocaleLowerCase().includes(search)) ||
      (move.consumer_phone && move.consumer_phone.replace(/\D/g,'') !== '' && search.replace(/\D/g,'') !== '' && move.consumer_phone.replace(/\D/g,'').includes(search.replace(/\D/g,'')))
    );
    // log && console.log(`Search Results`, searchResults);

    let activeMoves = searchResults.filter(move =>
      move.status &&
      (move.status.includes(`pickup`) || move.status.includes(`delivery`)) &&
      move.status !== `delivery successful` &&
      move.cancel_status === null
    );
    let plannedMoves = searchResults.filter(move =>
      (move.status === null || move.status === `dispatched`) &&
      move.cancel_status === null
    );
    let finishedMoves = searchResults.filter(move =>
      move.status === `delivery successful` ||
      move.cancel_status !== null
    );

    // log && console.log(`activeMoves:`, activeMoves);
    // log && console.log(`plannedMoves:`, plannedMoves);
    // log && console.log(`finishedMoves:`, finishedMoves);

    return { activeMoves, plannedMoves, finishedMoves, searchResults };
  }

  return (<>
    <div className={cls.root}>

      <Container maxWidth="xl">
       {ctx && ctx.userIsAuthenticated() &&  (
        <Query 
            query={GET_MOVES} 
            variables={{ customerId: parseInt(ctx.customerOverride || ctx.userProfile["https://hasura.io/jwt/claims"]['x-hasura-customer-id']), timestamp: timestamp }}
            onError={(error) => {console.error(error); Sentry.captureException(error)}}
          >
            {({ loading, data, error, refetch }) => {
              _refetch = refetch  //Make it available everywhere for event handling
              if (loading) return <Loading fixed />
              if (error) console.error("Error fetching moves:", error)
              if (data && data.moves) {
                moves = getFixedMoves(data.moves);
                log && console.log(`Fixed Moves`, moves);

                return (<>
                  <div className={cls.contain}>
                    {data.moves.length > 0 ? <>
                      <div className={cls.filter}>
                        <DashboardFilter moves={data.moves} handleClearFilters={handleClearFilters} tags={{ conciergeView: conciergeView, status: statusTags, make: makeTags, year: yearTags, color: colorTags }} setTags={{ conciergeView: setConciergeView, status: setStatusTags, make: setMakeTags, year: setYearTags, color: setColorTags }} />
                      </div>
                    </> : null}

                    <div className={cls.grid}>
                      <Grid container spacing={2} justify="flex-start" alignItems="center">

                        <Grid item md={3} xs={12}>
                          <Typography className={cls.head}>Dashboard</Typography>
                          <Link to="/moves/add">
                            <Button className={cls.actionBtn} variant="outlined" color="primary" size="large">Add&nbsp;Moves</Button>
                          </Link>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <div style={{textAlign: 'right'}}><AutoRefresh refetch={refetch} interval={60000} persistAs="dashboard" /></div>
                        </Grid>

                        <Grid item md={3} sm={7} xs={12}>
                          <TextField
                            fullWidth
                            label="Search"
                            placeholder="Search for a move..."
                            variant="outlined"
                            margin="none"
                            onChange={handleSearch}
                            className={cls.actionSearch}
                            InputProps={{
                              startAdornment: (<>
                                <InputAdornment style={{ verticalAlign: "top" }} position="start">
                                  <Icon color="disabled" fontSize="small">search</Icon>
                                </InputAdornment>
                              </>)
                            }}
                          />
                        </Grid>

                        <Grid item md={3} sm={5} xs={12}>
                          <TextField
                            fullWidth
                            select
                            label="Sort By"
                            placeholder="Sort moves by..."
                            variant="outlined"
                            margin="none"
                            value={sort}
                            onChange={handleSort}
                            className={cls.actionSort}
                          >
                            <MenuItem value={`new`}>Date (Newest)</MenuItem>
                            <MenuItem value={`old`}>Date (Oldest)</MenuItem>
                            <MenuItem value={`status`}>Status</MenuItem>
                            <MenuItem value={`make`}>Make</MenuItem>
                            <MenuItem value={`model`}>Model</MenuItem>
                            <MenuItem value={`year`}>Year</MenuItem>
                            <MenuItem value={`color`}>Color</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>

                      {conciergeView && moves && moves.searchResults && moves.searchResults.length > 0 ? <>
                        <DashboardGridByCustomer moves={moves.searchResults} />
                      </> : null}

                      {moves && moves.activeMoves && moves.activeMoves.length > 0 ? <>
                        <Divide spacer tip={`Track moves that are currently in-progress.`}>Active Moves</Divide>
                        <DashboardGrid moves={moves.activeMoves} />
                      </> : null}

                      {moves && moves.plannedMoves && moves.plannedMoves.length > 0 ? <>
                        <Divide spacer tip={`Track moves that you have planned.`}>Planned Moves</Divide>
                        <DashboardGrid moves={moves.plannedMoves} />
                      </> : null}

                      {moves && moves.finishedMoves && moves.finishedMoves.length > 0 ? <>
                        <Divide spacer tip={`Track moves that have been completed or cancelled within the past ${hours / 24} days.`}>Recent Moves</Divide>
                        <DashboardGrid moves={moves.finishedMoves} />
                      </> : null}

                      {moves && moves.activeMoves.length === 0 && moves.plannedMoves.length === 0 && moves.finishedMoves.length === 0 ? <>
                        <Divide spacer tip={`Track moves that have been completed or cancelled within the past ${hours / 24} days.`}>Recent Moves</Divide>
                        <div className={cls.notFound}>
                          <Typography className={cls.notFoundTxt}>NO RECENT MOVES FOUND</Typography>
                        </div>
                      </> : null}
                    </div>
                  </div>

                </>)
              }
            }}
          </Query>
        )}
      </Container>

    </div >
  </>)
}

////////// GRAPHQL //////////
const GET_MOVES = gql`
query get_moves($customerId: bigint!, $timestamp: timestamptz!) {
  moves(where: {active: {_eq: 1}, customer_id: {_eq: $customerId}, move_type: {_eq: "drive"}, pickup_time: {_gte: $timestamp}}, order_by: {pickup_time: desc}, limit: 100) {
    id
    status
    cancel_status
    reference_num
    manual_flag
    tags
    chargeable
    consumer_pickup
    consumer_name
    consumer_phone
    consumer_type
    vehicle_stock
    vehicle_vin
    vehicle_make
    vehicle_model
    vehicle_year
    vehicle_color
    customer {
      id
      name
    }
    lane {
      id
      description
    }
    movesByReturnRideId {
      id
      consumer_pickup
      tags
    }
    ready_by
    deliver_by
    pickup_time
    pickup_started
    pickup_arrived
    pickup_successful
    delivery_time
    delivery_started
    delivery_arrived
    delivery_successful
  }
}
`;