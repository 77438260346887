import React, { useState, useContext } from "react";
import { GlobalContext } from "../../../global-context";

import gql from "graphql-tag";
import * as Sentry from "@sentry/react";

import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheck, faMoneyCheckAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faCcVisa, faCcMastercard, faCcDiscover, faCcAmex } from '@fortawesome/free-brands-svg-icons';

import MethodAddEditModal from './MethodAddEditModal';
import MethodRemoveModal from './MethodRemoveModal';

const log = false;

////////// COMPONENT //////////
export default function MethodCard(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { method } = props;

  const [editModal, setEditModal] = useState({ open: false });
  const [removeModal, setRemoveModal] = useState({ open: false });

  // Make payment method primary
  const handlePrimary = () => {
    ctx.apolloClient.mutate({
      mutation: UPDATE_PRIMARY,
      variables: { customerId: method.customer_id, id: method.id },
      onError: (err) => {Sentry.captureException(err); ctx.handleNotifications(true, "error", "Query failed to change primary payment method: " + err.message) }
    }).then(res => {
      if (log && res.data.setTrue) var response = res.data.setTrue.returning[0];
      log && console.log(`>> Updated Payment Method #${response.id} for Customer #${response.customer_id}.`);
    }).catch(err => {
      console.log(`Error updating customer payment methods:`, err);
    });
  }

  // Function to dynamically create a new payment method card
  const createCard = (item) => {
    let type, cardType, num, exp, route, icon;

    if (item.type === `card`) {
      type = `Credit/Bank Card`;
      num = `${item.account_number}`.padStart(4, `0`);
      exp = `${item.expiration}`.padStart(4, `0`).substring(0, 2) + `/` + `${item.expiration}`.padStart(4, `0`).substring(2, 4);
      route = null;
      if (item.card_type === `visa`) {
        cardType = `Visa`;
        icon = <FontAwesomeIcon className={cls.icon} color="#0050a0" icon={faCcVisa} />;
      }
      else if (item.card_type === `master`) {
        cardType = `Mastercard`;
        icon = <FontAwesomeIcon className={cls.icon} color="#ff0000" icon={faCcMastercard} />;
      }
      else if (item.card_type === `discover`) {
        cardType = `Discover`;
        icon = <FontAwesomeIcon className={cls.icon} color="#ff7000" icon={faCcDiscover} />;
      }
      else if (item.card_type === `amex`) {
        cardType = `American Express`;
        icon = <FontAwesomeIcon className={cls.icon} color="#0080ff" icon={faCcAmex} />;
      }
      else {
        cardType = `Unknown Card`;
        icon = <FontAwesomeIcon className={cls.icon} color="#888" icon={faQuestionCircle} />;
      }
    }
    else if (item.type === `ach`) {
      type = `ACH Check`;
      cardType = null;
      num = `${item.account_number}`.padStart(4, `0`);
      exp = null;
      route = `${item.routing_number}`.padStart(9, `0`);
      icon = <FontAwesomeIcon className={cls.icon} color={ctx.theme.palette.text.primary} icon={faMoneyCheck} />;
    }
    else if (item.type === `echeck`) {
      type = `Electronic Check`;
      cardType = null;
      num = `${item.account_number}`.padStart(4, `0`);
      exp = null;
      route = `${item.routing_number}`.padStart(9, `0`);
      icon = <FontAwesomeIcon className={cls.icon} color={ctx.theme.palette.text.primary} icon={faMoneyCheckAlt} />;
    }
    else {
      type = `Unknown Type`;
      cardType = `N/A`;
      num = `N/A`;
      exp = `N/A`;
      route = `N/A`;
      icon = <FontAwesomeIcon className={cls.icon} color={ctx.theme.palette.text.secondary} icon={faQuestionCircle} />;
    }

    const handleEditModalOpen = (input = null) => {
      setEditModal({ open: true, input: input });
    }
    const handleEditModalClose = () => {
      setEditModal({ open: false });
    }

    const handleRemoveModalOpen = (input = null) => {
      setRemoveModal({ ...removeModal, open: true, input: input });
    }
    const handleRemoveModalClose = () => {
      setRemoveModal({ ...removeModal, open: false });
    }

    return (<>
      <MethodAddEditModal open={editModal.open} onClose={handleEditModalClose} methodInput={editModal.input} />
      <MethodRemoveModal open={removeModal.open} onClose={handleRemoveModalClose} methodInput={removeModal.input} />

      <div className={cls.payCard}>
        <Grid container spacing={0}>
          {item.type === `card` ?
            <>
              <div style={{ display: 'inline-block' }}>
                {icon}
              </div>
              <div style={{ display: 'inline-block' }}>
                <Typography className={cls.txtOver} variant="h6">{item.name !== `` || item.name !== null ? item.name : `Payment`}</Typography>
                <Typography className={cls.txtUnder} color="textSecondary">{cardType} ****{num}</Typography>
                <Typography className={cls.txtUnder} color="textSecondary">Expires: {exp}</Typography>
              </div>
            </>
            :
            <>
              <div style={{ display: 'inline-block' }}>
                {icon}
              </div>
              <div style={{ display: 'inline-block' }}>
                <Typography className={cls.txtOver} variant="h6">{item.name}</Typography>
                <Typography className={cls.txtUnder} color="textSecondary">{type} ****{num}</Typography>
                <Typography className={cls.txtUnder} color="textSecondary">Routing: {route}</Typography>
              </div>
            </>}
          <div style={{ width: '100%', height: '1px', margin: '24px 0', background: '#ddd' }} />
          <Grid item xs={12}>
            {item.primary ? <Typography color="textSecondary" style={{ float: 'left', verticalAlign: 'top', fontSize: '18px', fontWeight: 500 }}>Primary</Typography> : null}
            <Button className={cls.button} variant="contained" color="default" style={{ background: '#ff2040', color: '#fff' }} onClick={() => handleRemoveModalOpen(method)}>Remove</Button>
            <Button className={cls.button} variant="contained" color="primary" onClick={() => handleEditModalOpen(method)}>Edit</Button>
            {!item.primary ? <Button className={cls.button} variant="contained" color="secondary" onClick={() => handlePrimary()}>Make Primary</Button> : null}
          </Grid>
        </Grid>
      </div>
    </>)
  }

  return (<>
    {createCard(method)}
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  payCard: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px',
    background: '#fff',
  },
  icon: {
    fontSize: '64px',
    marginRight: theme.spacing(3),
  },
  button: {
    float: 'right',
    marginLeft: theme.spacing(1),
    boxShadow: 'none',
    "&:hover": {
      boxShadow: 'none',
    },
  },
  txtOver: {
    fontSize: '18px',
  },
  txtUnder: {
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

////////// GRAPHQL //////////
const UPDATE_PRIMARY = gql`
mutation update_primary($customerId: bigint!, $id: bigint!) {
  setFalse: update_arpaymentmethods(where: {customer_id: {_eq: $customerId}}, _set: {primary: false}) {
    affected_rows
    returning {
      id
      customer_id
    }
  }
  setTrue: update_arpaymentmethods(where: {id: {_eq: $id}}, _set: {primary: true}) {
    affected_rows
    returning {
      id
      customer_id
    }
  }
}
`;