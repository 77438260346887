import React, { useState, useEffect } from 'react';

import { TextField, InputAdornment, Icon } from '@material-ui/core';

////////// COMPONENT //////////
export default function NotesInput(props) {
  const { defaultNotes, onChange } = props;

  const [notes, setNotes] = useState(defaultNotes ? defaultNotes : '');
  const [chars, setChars] = useState(0);

  useEffect(() => {
    if (onChange) onChange(notes);
  }, [notes]);

  const handleInputChange = setHandler => event => {

    if (setHandler && event.target.value.length <= 255) {
      setChars(event.target.value.length);
      setHandler(event.target.value);
    }
  }

  return (<>
    <TextField
      fullWidth
      multiline
      label={`Notes/Instructions (${chars}/255)`}
      placeholder="Special instructions..."
      variant="outlined"
      margin="none"
      value={notes}
      onChange={handleInputChange(setNotes)}
      InputProps={{
        startAdornment: (<>
          <InputAdornment style={{ verticalAlign: "top" }} position="start">
            <Icon color="disabled" fontSize="small">chat</Icon>
          </InputAdornment>
        </>)
      }}
    />
  </>)
}