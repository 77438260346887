import React from 'react';
import { makeStyles, Stepper, Step, StepLabel } from '@material-ui/core';

////////// COMPONENT //////////
export default function ConciergeStepper(props) {
  const cls = useStyles();
  const { move, loaner } = props;

  let steps = [];

  if (loaner) steps = [
    `Driver is picking up your loaner vehicle`,
    `Driver is en route to your location`,
    `Driver has arrived at your location`,
    `Driver has picked up your vehicle`,
    `Vehicle is en route to service location`,
    `Vehicle has arrived at service location`,
    `Vehicle has been delivered successfully`,
  ]
  else steps = [
    `Driver is en route to your location`,
    `Driver has arrived at your location`,
    `Driver has picked up your vehicle`,
    `Vehicle is en route to service location`,
    `Vehicle has arrived at service location`,
    `Vehicle has been delivered successfully`,
  ]

  const getActiveStep = () => {
    if (loaner && loaner.status) {
      if (move.status && move.status !== ``) {
        if (loaner.status === `pickup started`) return 0;
        else if (move.status === `pickup started`) return 1;
        else if (move.status === `pickup arrived`) return 2;
        else if (move.status === `pickup successful`) return 3;
        else if (move.status === `delivery started`) return 4;
        else if (move.status === `delivery arrived`) return 5;
        else if (move.status === `delivery successful`) return 6;
        else return -1;
      }
    }
    else {
      if (move.status && move.status !== ``) {
        if (move.status === `pickup started`) return 0;
        else if (move.status === `pickup arrived`) return 1;
        else if (move.status === `pickup successful`) return 2;
        else if (move.status === `delivery started`) return 3;
        else if (move.status === `delivery arrived`) return 4;
        else if (move.status === `delivery successful`) return 5;
        else return -1;
      }
    }
  }

  return (<>
    <div className={cls.mainL}>
      <Stepper activeStep={getActiveStep()} alternativeLabel className={cls.stepperL}>
        {steps && steps.length > 0 && steps.map((step) =>
          <Step>
            <StepLabel className={cls.stepTxtL}>{step}</StepLabel>
          </Step>
        )}
      </Stepper>
    </div>

    <div className={cls.mainS}>
      <Stepper activeStep={getActiveStep()} orientation="vertical" className={cls.stepperS}>
        {steps && steps.length > 0 && steps.map((step) =>
          <Step>
            <StepLabel className={cls.stepTxtL}>{step}</StepLabel>
          </Step>
        )}
      </Stepper>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  mainL: {
    display: 'block',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  stepperL: {
    padding: 0,
    border: 0,
    margin: 0,
    background: '#ffffff00',
  },
  stepTxtL: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: '14px',
  },
  mainS: {
    display: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  stepperS: {
    background: '#ffffff00',
  },
  stepTxtS: {

  },
}));