import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Typography } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import fragments from '../utils/graphQL/fragments';
import * as Sentry from "@sentry/react";

import Loading from '../utils/Loading';
import MovesFilter from './moves/MovesFilter';
import MoveCancelModal from './moves/MoveCancelModal';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';

const log = false;

const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
}
const getDefaultRange = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  if (localRange) return localRange;
  else return `week`;
}
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  const localStart = localStorage.getItem(`move-index-start`);
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`).subtract(1, localRange)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return moment.utc(moment().startOf(`day`).subtract(1, `week`)).format();
}
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  const localEnd = localStorage.getItem(`move-index-end`);
  if (localRange && localRange !== `custom`) return moment.utc(moment().endOf(`day`)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return moment.utc(moment().endOf(`day`)).format();
}

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

////////// COMPONENT //////////
function Moves(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const [modal, setModal] = useState({ open: false });

  let customerId = parseInt(ctx.customerOverride || (ctx.userProfile && ctx.userProfile[`https://hasura.io/jwt/claims`] ? ctx.userProfile[`https://hasura.io/jwt/claims`][`x-hasura-customer-id`] : 0));

  const goToMoveDetails = (moveId) => {
    props.history.push(`/moves/${moveId}`);
  }

  // Control range picker
  const handleRangeChange = value => {
    log && console.log(`handleRangeChange() Value:`, value);
    localStorage.setItem(`move-index-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(moment.utc(moment().startOf(`day`).subtract(1, value)).format());
      setEnd(moment.utc(moment().endOf(`day`)).format());
    }
    else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  }

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`move-index-start`, newDate);
      setStart(newDate);
    }
    else {
      const newDate = moment.utc(moment(value).endOf(`day`)).format();
      localStorage.setItem(`move-index-end`, newDate);
      setEnd(newDate);
    }
  }

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(move => {
        const status = getStatus(move);
        if ((move.id && (move.id + ``).toLocaleLowerCase().includes(search)) ||
          (move.reference_num && move.reference_num.toLocaleLowerCase().includes(search)) ||
          (move.vehicle_stock && move.vehicle_stock.toLocaleLowerCase().includes(search)) ||
          (move.lane.description && move.lane.description.toLocaleLowerCase().includes(search)) ||
          (status && status.toLocaleLowerCase().includes(search))) {
          return true;
        }
        else return false;
      })
    }
  }

  const getStatus = (move) => {
    if (move.cancel_status && move.cancel_status !== ``) {
      if (move.cancel_status === `canceled`) return `Cancelled`;
      else if (move.cancel_status === `pending`) return `Cancel Pending`;
      else if (move.cancel_status === `seen`) return `Cancel Seen`;
      else if (move.cancel_status === `started`) return `Cancel Started`;
      else return move.cancel_status;
    }
    else if (move.status && move.status !== ``) {
      if (move.status === `dispatched`) return `Dispatched`;
      else if (move.status === `pickup started`) return `Pickup Started`;
      else if (move.status === `pickup arrived`) return `Pickup Arrived`;
      else if (move.status === `pickup successful`) return `Pickup Successful`;
      else if (move.status === `delivery started`) return `Delivery Started`;
      else if (move.status === `delivery arrived`) return `Delivery Arrived`;
      else if (move.status === `delivery successful`) return `Delivery Successful`;
      else if (move.status === `droppedOff`) return `Dropped Off`;
      else if (move.status === `canceled`) return `Cancelled`;
      else if (move.status === `failed`) return `Failed`;
      else return move.status;
    }
    else return `Unplanned`;
  }

  const generateCSV = (moves) => {
    // Create rows and options for CSV
    const createCsvRow = (move) => {
      return {
        MOVE_ID: move.id,
        REF_NUM: move.reference_num ? move.reference_num : `-`,
        MOVE_TIME: moment(move.pickup_started ? move.pickup_started : move.pickup_time).format(`MM/DD/YYYY LT`),
        STOCK: move.vehicle_stock ? move.vehicle_stock.toUpperCase() : `-`,
        LANE: move.lane.description ? move.lane.description : `Unknown Lane`,
        STATUS: getStatus(move),
      }
    }
    const csvRows = moves.map(move => createCsvRow(move));
    const csvOptions = {
      filename: `${moves[0].customer.name.replace(/ /g, "_")}_Moves`,
      showTitle: true,
      title: `${moves[0].customer.name.replace(/ /g, "_")}_Moves`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  const handleModalOpen = (input = null) => {
    setModal({ ...modal, open: true, input: input });
  }
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  }

  const getTableActions = (moves) => {
    return [
      { name: `generate-csv`, label: `Generate\xa0CSV`, data: { moves: moves }, handler: handleContextMenuClick },
    ];
  }
  const getRowActions = (move) => {
    return [
      { name: `move-details`, label: `Move\xa0Details`, data: { move: move }, handler: handleContextMenuClick },
      { name: `cancel-move`, label: `Cancel\xa0Move`, data: { move: move }, handler: handleContextMenuClick, hide: move.cancel_status || move.status === (`delivery successful`) },
    ];
  }

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `generate-csv`) {
      generateCSV(data.moves);
      return
    }
    if (data.action.name === `move-details`) {
      goToMoveDetails(data.move.id);
      return
    }
    if (data.action.name === `cancel-move`) {
      handleModalOpen(data.move);
      return
    }
  }

  let _refetch = null

  return (<>
    <MoveCancelModal open={modal.open} onClose={handleModalClose} moveInput={modal.input} />

    <div className={cls.root}>
      <Container maxWidth="lg">
        <Typography className={cls.head}>Move Index</Typography>

        <MovesFilter refetch={_refetch} range={range} start={start} end={end} onRangeChange={handleRangeChange} onDateChange={handleDateChange} disablePickers={disablePickers} />

        {/* <Divide spacer tip="See all moves within the specified time period.">Table View</Divide> */}
        {ctx && ctx.userIsAuthenticated() && (<Query query={GET_MOVES} variables={{ start: start, end: end, customerId: customerId }} onError={(error) => {console.error(error); Sentry.captureException(error)}}>
            {({ loading, data, refetch }) => {
              _refetch = refetch
              if (loading) return <Loading fixed />
              if (data && data.moves && data.moves.length > 0) {
                log && console.log(`Moves for Move Index:`, data.moves);
                const filteredData = applyFilters(data.moves)
                const headers = [
                  { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
                  { id: `REF_NUM`, alignLeft: true, numeric: true, label: `Ref\xa0#` },
                  { id: `MOVE_TIME`, alignLeft: true, numeric: true, label: `Move\xa0Time` },
                  { id: `STOCK`, alignLeft: false, numeric: true, label: `Stock` },
                  { id: `LANE`, alignLeft: true, numeric: false, label: `Lane` },
                  { id: `STATUS`, alignLeft: false, numeric: false, label: `Status` },
                ]
                const rows = filteredData.map(move => {
                  return {
                    MOVE_ID: move.id,
                    REF_NUM: move.reference_num ? move.reference_num : `-`,
                    MOVE_TIME: moment(move.pickup_started ? move.pickup_started : move.pickup_time).format(`MM/DD/YYYY LT`),
                    STOCK: move.vehicle_stock ? move.vehicle_stock.toUpperCase() : `-`,
                    LANE: move.lane.description ? move.lane.description : `Unknown Lane`,
                    STATUS: getStatus(move),
                    move: move,
                  }
                })

                return (<>
                  <AccordianTable
                    title={`${rows.length} Moves`}
                    headers={headers}
                    rows={rows}
                    actions={getTableActions(data.moves)}
                    search={search}
                    defaultOrder={defaultOrder}
                    defaultOrderBy={defaultOrderBy}
                    order={order}
                    orderBy={orderBy}
                    tablePage={tablePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    setSearch={setSearch}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    setTablePage={setTablePage}
                    setRowsPerPage={setRowsPerPage}
                    setExpandedRowId={setExpandedRowId}
                    className={cls.table}
                    refetch={refetch}
                    refreshPersistAs={'moves'}
                  >
                    {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <AccordianRow
                          key={`index-move-${row.MOVE_ID}`}
                          rowId={row.MOVE_ID}
                          expandedRowId={expandedRowId}
                          setExpandedRowId={setExpandedRowId}
                          columns={[
                            { align: 'left', value: row.MOVE_ID },
                            { align: 'left', value: row.REF_NUM },
                            { align: 'left', value: row.MOVE_TIME },
                            { align: 'right', value: row.STOCK },
                            { align: 'left', value: row.LANE },
                            { align: 'right', value: row.STATUS },
                          ]}
                          actions={getRowActions(row.move)}
                          onClick={() => goToMoveDetails(row.move.id)}
                          className={cls.row}
                        >
                          <div></div>
                        </AccordianRow>
                      ))}
                  </AccordianTable>
                </>)
              }
              else return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO MOVES FOUND</Typography>
                </div>
              )
            }}
          </Query>
        )}
      </Container>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  head: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// GRAPHQL //////////
const GET_MOVES = gql`
query get_moves($start: timestamptz!, $end: timestamptz!, $customerId: bigint!) {
  moves(where: {
    active: {_eq: 1}, 
    move_type: {_ilike: "drive"}, 
    customer_id: {_eq: $customerId}, 
    _or: [
      {pickup_time: {_gte: $start, _lte: $end}},
      {_and: [{ready_by: {_gte: $start, _lte: $end}},
      {pickup_time: {_is_null: true}}]},
    ]
  }, order_by: {id: desc}) {
    ...Move
  }
}
${fragments.move}
`;

////////// EXPORT //////////
export default withRouter(Moves);