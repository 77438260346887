import React from 'react';
import { makeStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

////////// COMPONENT //////////
export default function MovesFilter(props) {
  const cls = useStyles();

  const { range, start, end, onRangeChange, onDateChange, disablePickers, refetch } = props;

  const handleRangeChange = event => {
    onRangeChange(event.target.value);
  }
  
  const handleDateChange = name => event => {
    onDateChange(event, name);
  }

  return (<>
    <div className={cls.main}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <Grid container spacing={2}>

          <Grid item sm={6} xs={12}>
            <TextField
              select
              fullWidth
              label="Date Range"
              placeholder="Select a date range..."
              variant="outlined"
              margin="none"
              value={range}
              onChange={handleRangeChange}
            >
              <MenuItem value={`day`}>Today</MenuItem>
              <MenuItem value={`week`}>Past Week</MenuItem>
              <MenuItem value={`month`}>Past Month</MenuItem>
              <MenuItem value={`year`}>Past Year</MenuItem>
              <MenuItem value={`custom`}>Custom Range</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={3} xs={6}>
            <DatePicker
              disabled={disablePickers}
              autoOk
              maxDate={end}
              orientation="portrait"
              format="MM/dd/yyyy"
              label="Start Date"
              margin="none"
              inputVariant="outlined"
              value={start}
              onChange={handleDateChange(`start`)}
              className={cls.date}
            />
          </Grid>

          <Grid item sm={3} xs={6}>
            <DatePicker
              disabled={disablePickers}
              autoOk
              minDate={start}
              orientation="portrait"
              format="MM/dd/yyyy"
              label="End Date"
              margin="none"
              inputVariant="outlined"
              value={end}
              onChange={handleDateChange(`end`)}
              className={cls.date}
            />
          </Grid>

        </Grid>

      </MuiPickersUtilsProvider>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    marginBottom:theme.spacing(2),
  },
  date: {
    width: '100%',
  },
}));