import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { GlobalContext } from '../global-context';

import { withStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Divider,
  Typography,
  List,
  // ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Icon,
  Tooltip,
} from '@material-ui/core';
import { palette, spacing, typography } from '@material-ui/system';

import Routes from '../Routes';
import NotificationPopup from './reusable/NotificationPopup';
import CustomerSelect from './reusable/selectors/CustomerSelect';
import './../index.css';
import 'typeface-roboto';
const logo = require('../static/logo-banner-light.svg');

let log = false;

const Box = styled.div`
  ${palette}${spacing}${typography}
`;
const drawerWidth = 320;

const styles = theme => ({
  avatar: {
    display: 'inline-flex',
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    maxHeight: '64px',
    backgroundColor: theme.palette.secondary.dark,
    boxShadow: '0px 0px 16px #00000032',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },

  content: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  body: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    maxHeight: '100%',
  },
  navPad: {
    position: 'relative',
    width: '100%',
    minHeight: '64px',
    maxHeight: '64px',
  },
  page: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: 'overlay',
  },
});

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  componentWillMount = async () => {
    if (log) console.log(this.context);
    // Authenticate user and initialize Apollo Client
    if (this.context.auth0.isAuthenticated) {
      try {
        let { __raw: token } = await this.context.auth0.getIdTokenClaims();
        this.context.setUserAuth(this.context.auth0.user, token);
        this.context.setupApollo(token);
      } catch (err) {
        // In the event of a failed authentication, route back to the public page
        // This will allow the user to attempt the login again without an infinite render loop
        this.props.history.push('/');
      }
    }
  };

  handleCustomerOverride = global => {
    if (global.userIsAuthenticated()) {
      let allowedRoles = global.userProfile['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
      let allowedCustomers = global.userProfile['https://hasura.io/jwt/claims']['x-hasura-allowed-customers'];
      let customersArray = allowedCustomers ? JSON.parse(allowedCustomers.replace('{', '[').replace('}', ']')) : [];
      //Admin can emulated any customer; dealer-admin can emulate only allowed customers; other users cannot emulate
      if (allowedRoles.includes('admin')) {
        return <CustomerSelect />;
      } else if (allowedRoles.includes('dealer-admin') && customersArray.length > 1) {
        return <CustomerSelect />;
      } else return null;
    } else return null;
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GlobalContext.Consumer>
          {global => {
            return (
              <div className={classes.root}>
                <NotificationPopup
                  show={this.context.notificationShow}
                  variant={this.context.notificationVariant}
                  message={this.context.notificationMessage}
                  handleClose={this.context.handleNotifications}
                />
                <CssBaseline />
                <AppBar
                  position='absolute'
                  className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                >
                  <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                    {!this.state.open && (
                      <Tooltip title='Menu'>
                        <IconButton
                          style={{ marginRight: '8px' }}
                          color='inherit'
                          aria-label='Open drawer'
                          onClick={this.handleDrawerOpen}
                          className={classNames(classes.menuButton)}
                        >
                          <Icon>menu</Icon>
                        </IconButton>
                      </Tooltip>
                    )}

                    <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
                      <Link to='/'>
                        <Box ml='0px'>
                          {!this.state.open && (
                            <img style={{ marginTop: '14px' }} alt='logo-hopdrive' width='180px' src={logo} />
                          )}
                        </Box>
                      </Link>
                    </Typography>

                    <div>
                      <Tooltip title='Call Dispatch at (800) 913-7674'>
                        <IconButton>
                          <Icon style={{ color: '#fff' }}>phone</Icon>
                          <Typography style={{ color: '#fff', marginLeft: '10px' }}>(800) 913-7674</Typography>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Text Dispatch at (804) 487-3935'>
                        <IconButton>
                          <Icon style={{ color: '#fff' }}>chat</Icon>
                          <Typography style={{ color: '#fff', marginLeft: '10px' }}>(804) 487-3935</Typography>
                        </IconButton>
                      </Tooltip>
                    </div>

                    {this.handleCustomerOverride(global)}

                    {global.userIsAuthenticated() && (
                      <div>
                        <Link to='/profile'>
                          <Tooltip title='Profile'>
                            <IconButton>
                              <Icon style={{ color: '#fff' }}>account_circle</Icon>
                            </IconButton>
                          </Tooltip>
                        </Link>
                        <Tooltip title='Sign Out'>
                          <IconButton
                            onClick={() => {
                              global.logout();
                            }}
                          >
                            <Icon style={{ color: '#fff' }}>exit_to_app</Icon>
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </Toolbar>
                </AppBar>
                <div onClick={this.handleDrawerClose}>
                  <Drawer
                    // variant="permanent"
                    classes={{
                      paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    }}
                    open={this.state.open}
                  >
                    <div className={classes.toolbarIcon}>
                      <IconButton onClick={this.handleDrawerClose}>
                        <Icon>chevron_left</Icon>
                      </IconButton>
                    </div>

                    <Divider />
                    <List>
                      {!global.userIsAuthenticated() && (
                        <ListItem button component={Link} to='/moves' onClick={this.handleDrawerClose}>
                          <ListItemIcon>
                            <Icon>home</Icon>
                          </ListItemIcon>
                          <ListItemText primary='Login' />
                        </ListItem>
                      )}

                      {global.userIsAuthenticated() && (
                        <>
                          <ListItem button component={Link} to='/' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>dashboard</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Dashboard' />
                          </ListItem>

                          <ListItem button component={Link} to='/moves/add' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>queue</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Add Moves' />
                          </ListItem>
                        </>
                      )}
                    </List>
                    <Divider />
                    {global.userIsAuthenticated() && (
                      <>
                        <List>
                          <ListItem button component={Link} to='/moves' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>directions_car</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Moves' />
                          </ListItem>

                          <ListItem button component={Link} to='/locations' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>store_mall_directory</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Locations' />
                          </ListItem>

                          <ListItem button component={Link} to='/appraisals' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>assignment_turned_in</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Appraisals' />
                          </ListItem>
                        </List>
                        <Divider />
                        <List>
                          <ListItem button component={Link} to='/billing' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>monetization_on</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Billing' />
                          </ListItem>

                          <ListItem button component={Link} to='/invoices' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>receipt</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Invoices' />
                          </ListItem>
                        </List>
                        {/* <Divider />
                      <List>

                        <ListSubheader>Legacy</ListSubheader>
                        <ListItem button component={Link} to="/legacy-moves/add" onClick={this.handleDrawerClose}>
                          <ListItemIcon>
                            <Icon>queue</Icon>
                          </ListItemIcon>
                          <ListItemText primary="Add Moves (Legacy)" />
                        </ListItem>

                      </List> */}
                      </>
                    )}
                  </Drawer>
                </div>
                <main className={classes.content}>
                  <div style={{ marginTop: '100px' }} id='login' hidden={global.userIsAuthenticated()}></div>
                  <div className={classes.body}>
                    <div className={classes.navPad} />
                    <div className={classes.page}>
                      <Routes />
                    </div>
                  </div>
                </main>
              </div>
            );
          }}
        </GlobalContext.Consumer>
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

Layout.contextType = GlobalContext;

export default withStyles(styles)(withRouter(Layout));
