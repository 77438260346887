import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../global-context';
import gql from 'graphql-tag';
import { makeStyles, Typography, Grid, TextField, InputAdornment, Icon } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';

const log = false;

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

////////// COMPONENT //////////
export default function DisputeModal(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { open, onClose, disputeInput, initialFormState } = props;

  // Controls which form should render ('dispute-add', 'dispute-cancel')
  const [formState, setFormState] = useState(`dispute-add`);

  const [reason, setReason] = useState(``);

  useEffect(() => {
    if (disputeInput && disputeInput.dispute_reason) setReason(disputeInput.dispute_reason);
    if (initialFormState) setFormState(initialFormState);
  }, [disputeInput, initialFormState])

  const handleInputChange = setHandler => event => {
    if (setHandler && event) setHandler(event.target.value);
  }

  const handleClose = () => {
    if (onClose) onClose();
    setReason(``);
  }

  const applyDispute = async () => {
    try {
      const updateRes = disputeInput.disputed ? await cancelDispute() : await addDispute();
      if (updateRes) {
        log && console.log(`>> Dispute added/cancelled successfully.`);
        handleClose();
      }
    }
    catch (err) {
      console.log(`Error updating dispute records:`, err);
    }
  }

  const addDispute = async () => {
    return await ctx.apolloClient.mutate({
      mutation: UPDATE_ARMOVE_DISPUTED,
      variables: { moveId: disputeInput.move_id, disputed: true, disputeReason: reason }
    });
  }
  const cancelDispute = async () => {
    return await ctx.apolloClient.mutate({
      mutation: UPDATE_ARMOVE_DISPUTED,
      variables: { moveId: disputeInput.move_id, disputed: false, disputeReason: `` }
    });
  }

  return (<>
    <Modal open={open}>

      {formState === `dispute-add` && disputeInput && <>
        <ModalHeader handleClose={handleClose}>Dispute</ModalHeader>

        <ModalContent subtitle={`By disputing a move, an administrator will review your reason for the dispute. If you do not provide a reason, we will not be able to assist you. You should only dispute if a detail of this move is incorrect, or you want to exclude this move from\xa0the\xa0total.`}>
          <div className={cls.charge}>
            <Typography variant="subtitle2" className={cls.detailMain}>Charge Details:</Typography>
            {disputeInput.details.map((detail, i) => {
              return (
                <React.Fragment key={i}>
                  <div className={cls.block}>
                    <Typography variant="body2" className={cls.beeg}>{capFirst(detail.name)}</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={9}><Typography className={cls.smol + " " + cls.typeLeft}>{detail.notes}</Typography></Grid>
                      <Grid item xs={3}><Typography className={cls.smol + " " + cls.typeRight}>${detail.amount.toFixed(2)}</Typography></Grid>
                    </Grid>
                  </div>
                  <div className={cls.lineBreak} />
                </React.Fragment>
              )
            })}
            {disputeInput.discount_amount > 0 && <>
              <div className={cls.block}>
                <Typography variant="body2" className={cls.beeg}>Discount</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={9}><Typography className={cls.smol + " " + cls.typeLeft}>{disputeInput.discount_reason}</Typography></Grid>
                  <Grid item xs={3}><Typography className={cls.smol + " " + cls.typeRight}>${disputeInput.discount_amount.toFixed(2)}</Typography></Grid>
                </Grid>
              </div>
              <div className={cls.lineBreak} />
            </>}
            <div className={cls.smallBreak} />
            <Typography variant="subtitle2" className={cls.detailVal}>
              Total Move Charge&nbsp;&nbsp;&nbsp;${checkNeg(disputeInput.due_amount - disputeInput.discount_amount).toFixed(2)}
            </Typography>
          </div>
          <div className={cls.bigBreak} />
          <TextField
            required
            fullWidth
            multiline
            label="Reason"
            placeholder="Enter a brief reason for the dispute..."
            variant="outlined"
            value={reason}
            onChange={handleInputChange(setReason)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: "top" }} position="start">
                  <Icon color="disabled" fontSize="small">announcement</Icon>
                </InputAdornment>
              )
            }}
          />
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => applyDispute()} disabled={reason.length < 1}>Dispute</ModalAction>
          <ModalAction onClick={() => handleClose()} color="secondary">Cancel</ModalAction>
        </ModalFooter>
      </>}

      {formState === `dispute-cancel` && disputeInput && <>
        <ModalHeader handleClose={handleClose}>Cancel Dispute</ModalHeader>

        <ModalContent subtitle={`By canceling a dispute, the move will no longer be flagged for review. No additional action\xa0is\xa0required.`}>
          <div className={cls.disputeBox}>
            <Typography className={cls.disputeHead}>Your Disputed Reason: </Typography>
            <Typography className={cls.disputeBody}>{disputeInput.dispute_reason}</Typography>
          </div>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => applyDispute()}>Confirm</ModalAction>
          <ModalAction onClick={() => handleClose()} color="secondary">Cancel</ModalAction>
        </ModalFooter>
      </>}

    </Modal>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  beeg: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  smol: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  charge: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px',
    background: theme.palette.background.dark,
  },
  detailMain: {
    marginBottom: theme.spacing(0.75),
    fontSize: '20px',
    lineHeight: '24px',
  },
  detailKey: {
    textAlign: 'left',
    fontSize: '16px',
    lineHeight: '20px',
  },
  detailVal: {
    textAlign: 'right',
    fontSize: '16px',
    lineHeight: '20px',
  },

  disputeBox: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
  },
  disputeHead: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 500,
  },
  disputeBody: {
    fontSize: '14px',
    fontWeight: 400,
  },

  block: {
    display: 'block',
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(0.5),
  },
  break: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(2.5),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '4px 0',
    background: '#ddd',
  },
  typeLeft: {
    textAlign: 'left',
  },
  typeRight: {
    textAlign: 'right',
  },
}));

////////// GRAPHQL //////////
const UPDATE_ARMOVE_DISPUTED = gql`
mutation update_armove_disputed($moveId: bigint!, $disputed: Boolean!, $disputeReason: String!) {
  update_moves(
    where: {
      id: {_eq: $moveId}
    }, 
    _set: {
      disputed: $disputed,
      dispute_reason: $disputeReason,
      updatedat: "now()"
    }
  ) {
    affected_rows
    returning {
      id
      disputed
      dispute_reason
      updatedat
    }
  },
  update_armoves(
    where: {
      move_id: {_eq: $moveId}
    }, 
    _set: {
      disputed: $disputed,
      dispute_reason: $disputeReason,
      updatedat: "now()"
    }
  ) {
    affected_rows
    returning {
      id
      move_id
      disputed
      dispute_reason
      updatedat
    }
  }
}
`;