import React, { useState, useContext } from "react";
import { GlobalContext } from "../../global-context";

import { makeStyles, Container, Typography, Switch } from "@material-ui/core";

import gql from "graphql-tag";
import { Query } from "react-apollo";
import * as Sentry from "@sentry/react";

import Loading from '../utils/Loading';
import MethodTable from './billing/MethodTable';
import AutoRefresh from '../reusable/AutoRefresh';

const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  fakePaper: {
    width: 'fit-content',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px',
    background: '#fff',
  },
  pref: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  head: {
    marginBottom: theme.spacing(2),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  txtTitle: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
  },
  txtItem: {
    fontSize: '18px',
    fontWeight: 500,
    textAlign: 'center',
  },
}));

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

////////// COMPONENT //////////
export default function Billing() {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [billing, setBilling] = useState(`Unknown`);
  const [autoPay, setAutoPay] = useState(false);

  const customerId = parseInt(ctx.customerOverride || (ctx.userProfile["https://hasura.io/jwt/claims"] ? ctx.userProfile["https://hasura.io/jwt/claims"]['x-hasura-customer-id'] : 0 ));

  const setPrefs = async (ap) => {
    ctx.apolloClient.mutate({
      mutation: UPDATE_PREFERENCES,
      variables: { customerId: customerId, autoPay: ap },
      onError: err => {Sentry.captureException(err); ctx.handleNotifications(true, "error", "Query failed to update customer billing prefs: " + err.message)}
    }).then(res => {
      if (res.data.update_customers) {
        let prefs = res.data.update_customers.returning[0];
        log && console.log(`>> Set Customer #${prefs.id} to Auto-Pay '${prefs.auto_pay}'.`);
      }
    }).catch(err => {
      console.log(`Error updating customer billing prefs:`, err);
    });
  }

  const handleCheck = async (event) => {
    setAutoPay(event.target.checked);
    await setPrefs(event.target.checked);
  };

  return (<>
    <div className={cls.root}>
      { ctx && ctx.userIsAuthenticated() && (
        <Container className={cls.container} maxWidth="lg">
          <Typography className={cls.head}>Billing Preferences</Typography>
          <div style={{ width: '100%', height: '8px' }} />
          <Query
            query={GET_PREFERENCES(`query`)}
            variables={{ customerId: customerId }}
            // onError={(error) => {console.error(error); Sentry.captureException(error)}}
            >
            {({ loading, data}) => {
              if (loading) return <Loading fixed />
              if (data && data.customers && data.customers.length > 0) {
                let prefs = data.customers[0];
                log && console.log(`Got Customer #${prefs.id} with Billing Frequency '${prefs.billing_frequency}' and Auto-Pay '${prefs.auto_pay}'.`);
                setBilling(capFirst(prefs.billing_frequency));
                setAutoPay(prefs.auto_pay);
                return (
                  <div className={cls.fakePaper}>
                    <div className={cls.pref}>
                      <Typography className={cls.txtTitle} color="textSecondary" variant="h6">Customer Name</Typography>
                      <Typography className={cls.txtItem} variant="h6">{prefs.name}</Typography>
                    </div>
                    <div className={cls.pref} style={{ width: '64px' }} />
                    <div className={cls.pref}>
                      <Typography className={cls.txtTitle} color="textSecondary" variant="h6">Billing&nbsp;Frequency</Typography>
                      <Typography className={cls.txtItem} variant="h6">{capFirst(billing)}</Typography>
                    </div>
                    <div className={cls.pref} style={{ width: '64px' }} />
                    <div className={cls.pref}>
                      <Typography className={cls.txtTitle} color="textSecondary" variant="h6">Auto&nbsp;Pay</Typography>
                      <Switch
                        disabled={billing === `Daily` ? true : false}
                        checked={autoPay}
                        onChange={(event) => handleCheck(event)}
                        value={autoPay}
                        color="primary"
                        inputProps={{ 'aria-label': 'checkbox' }}
                      />
                    </div>
                  </div>
                )
              }
              else {
                return <Typography variant="h6" className={cls.txtItem}>No Billing Prefs Found</Typography>
              }
            }
            }
          </Query>
          <div style={{ width: '100%', height: '16px' }} />
          <div style={{ width: '100%', height: '16px' }} />
          <Typography className={cls.head}>Payment Methods</Typography>
          <Query
            query={GET_PAYMENT_METHODS(`query`)}
            variables={{ customerId: customerId }}
            onError={(error) => {console.error(error); Sentry.captureException(error)}}>
            {({ loading, data }) => {
              if (loading) return <Loading fixed />
              if (data && data.arpaymentmethods && data.arpaymentmethods.length > 0) {
                let methods = data.arpaymentmethods;
                log && console.log(`Got ${methods.length} payment methods.`);
                return <MethodTable methods={methods} />
              }
              else {
                return <MethodTable methods={[]} />
              }
            }
            }
          </Query>
          <div style={{ width: '100%', height: '16px' }} />
        </Container>)
      }
    </div>
  </>)
}

////////// GRAPHQL //////////
const GET_PAYMENT_METHODS = (type = `query`) => gql`
${type} get_payment_methods($customerId: bigint!) {
  arpaymentmethods(where: {customer_id: {_eq: $customerId}, active: {_eq: true}, type: {_neq: "manual"}}, order_by: {primary: desc, name: asc}) {
    id
    customer_id
    gateway_token
    active
    type
    routing_number
    account_type
    account_number
    expiration
    card_type
    primary
    name
    source
  }
}
`;

const GET_PREFERENCES = (type = `query`) => gql`
${type} get_preferences($customerId: bigint!) {
  customers(where: {id: {_eq: $customerId}, active: {_eq: 1}}) {
    id
    name
    billing_frequency
    auto_pay
  }
}
`;

const UPDATE_PREFERENCES = gql`
mutation update_preferences($customerId: bigint!, $autoPay: Boolean!) {
  update_customers(where: {id: {_eq: $customerId}, active: {_eq: "1"}}, _set: {auto_pay: $autoPay}) {
    affected_rows
    returning {
      id
      name
      billing_frequency
      auto_pay
    }
  }
}
`;