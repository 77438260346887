import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Typography, Tooltip, Icon } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import fragments from '../utils/graphQL/fragments';
import * as Sentry from "@sentry/react";

import Loading from '../utils/Loading';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';

const log = false;

const defaultOrder = `asc`;
const defaultOrderBy = `NAME`;

////////// COMPONENT //////////
function Locations(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  let customerId = parseInt(ctx.customerOverride || (ctx.userProfile[`https://hasura.io/jwt/claims`] ? ctx.userProfile[`https://hasura.io/jwt/claims`][`x-hasura-customer-id`] : 0));

  const handleLocationFavorite = async (loc) => {
    if (loc) {
      try {
        const res = await ctx.apolloClient.mutate({
          mutation: UPDATE_LOCATION_FAVORITE,
          variables: { id: loc.id, fav: !loc.favorite }
        });
        if (res) log && console.log(`>> UPDATED Favorite Location:`, res.data.update_locations);
      }
      catch (err) {
        log && console.log(`Error updating location:`, err);
        ctx.handleNotifications(true, `error`, `Failed to update location: ` + err.toString());
      }
    }
    else log && console.log(`Error: Cannot set favorite of an empty location!`);
  }

  const goToLocationDetails = (locationId) => {
    props.history.push(`/locations/${locationId}`);
  }

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(loc => {
        const name = getName(loc);
        if ((name && name.toLocaleLowerCase().includes(search)) ||
          (loc.address && loc.address.toLocaleLowerCase().includes(search)) ||
          (loc.email && loc.email.toLocaleLowerCase().includes(search)) ||
          (loc.phone && loc.phone.toLocaleLowerCase().includes(search))) {
          return true;
        }
        else return false;
      })
    }
  }

  const getName = (loc) => {
    if (loc.nickname) return loc.nickname;
    else if (loc.name) return loc.name;
    else return `Unknown Location`;
  }

  const generateCSV = (locs) => {
    // Create rows and options for CSV
    const createCsvRow = (loc) => {
      return {
        NAME: getName(loc),
        ADDRESS: loc.address ? loc.address : `Unknown Address`,
        EMAIL: loc.email ? loc.email : `-`,
        PHONE: loc.phone ? loc.phone : `-`,
      }
    }
    const csvRows = locs.map(loc => createCsvRow(loc));
    const csvOptions = {
      filename: `${locs[0].customer.name.replace(/ /g, "_")}_Locations`,
      showTitle: true,
      title: `${locs[0].customer.name.replace(/ /g, "_")}_Locations`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  const getTableActions = (locations) => {
    return [
      { name: `generate-csv`, label: `Generate\xa0CSV`, data: { locations: locations }, handler: handleContextMenuClick },
    ];
  }
  const getRowActions = (location) => {
    return [
      { name: `location-details`, label: `Location\xa0Details`, data: { location: location }, handler: handleContextMenuClick },
    ];
  }

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `generate-csv`) {
      generateCSV(data.locations);
      return
    }
    if (data.action.name === `location-details`) {
      goToLocationDetails(data.location.id);
      return
    }
  }

  return (<>
    <div className={cls.root}>
      <Container maxWidth="lg">
        <Typography className={cls.head}>Location Index</Typography>

        {/* <Divide spacer tip="See all of your stored locations.">Table View</Divide> */}
        { ctx && ctx.userIsAuthenticated() && (
          <Query query={GET_LOCATIONS} variables={{ customerId: customerId }} onError={(error) => {console.error(error); Sentry.captureException(error)}}>
            {({ loading, data, refetch }) => {
              if (loading) return <Loading fixed />
              if (data && data.locations && data.locations.length > 0) {
                log && console.log(`Locations for Location Index:`, data.locations);
                const filteredData = applyFilters(data.locations)
                const headers = [
                  { id: `NAME`, alignLeft: true, numeric: false, label: `Name` },
                  { id: `ADDRESS`, alignLeft: true, numeric: false, label: `Address` },
                  { id: `EMAIL`, alignLeft: true, numeric: false, label: `Email` },
                  { id: `PHONE`, alignLeft: true, numeric: false, label: `Phone` },
                  { id: `FAVORITE`, alignLeft: false, numeric: false, label: `Favorite` },
                ]
                const rows = filteredData.map(loc => {
                  return {
                    NAME: getName(loc),
                    ADDRESS: loc.address ? loc.address : `Unknown Address`,
                    EMAIL: loc.email ? loc.email : `-`,
                    PHONE: loc.phone ? loc.phone : `-`,
                    FAVORITE:
                      <Tooltip placement="top" title={loc.favorite ? `Unfavorite Location` : `Favorite Location`}>
                        <Icon className={loc.favorite ? cls.heartActive : cls.heart} onClick={() => handleLocationFavorite(loc)}>{loc.favorite ? `favorite` : `favorite_border`}</Icon>
                      </Tooltip>,
                    loc: loc,
                  }
                })

                return (<>
                  <AccordianTable
                    title={`${rows.length} Locations`}
                    size={`small`}
                    headers={headers}
                    rows={rows}
                    actions={getTableActions(data.locations)}
                    search={search}
                    defaultOrder={defaultOrder}
                    defaultOrderBy={defaultOrderBy}
                    order={order}
                    orderBy={orderBy}
                    tablePage={tablePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    setSearch={setSearch}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    setTablePage={setTablePage}
                    setRowsPerPage={setRowsPerPage}
                    setExpandedRowId={setExpandedRowId}
                    className={cls.table}
                    refetch={refetch}
                    refreshPersistAs={'locations'}
                  >
                    {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <AccordianRow
                          key={`index-location-${row.loc.id}`}
                          rowId={row.loc.id}
                          expandedRowId={expandedRowId}
                          setExpandedRowId={setExpandedRowId}
                          columns={[
                            { align: 'left', value: row.NAME },
                            { align: 'left', value: row.ADDRESS },
                            { align: 'left', value: row.EMAIL },
                            { align: 'left', value: row.PHONE },
                            { align: 'right', value: row.FAVORITE, preventClick: true },
                          ]}
                          actions={getRowActions(row.loc)}
                          onClick={() => goToLocationDetails(row.loc.id)}
                          className={cls.row}
                        >
                          <div></div>
                        </AccordianRow>
                      ))}
                  </AccordianTable>
                </>)
              }
              else return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO LOCATIONS FOUND</Typography>
                </div>
              )
            }}
          </Query>)
        }
      </Container>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  head: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  heart: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  heartActive: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// GRAPHQL //////////
const GET_LOCATIONS = gql`
query get_locations($customerId: bigint!) {
  locations(where: {active: {_eq: 1}, customer_id: {_eq: $customerId}}, order_by: [{favorite: desc}, {name: asc}]) {
    ...Location
  }
}
${fragments.location}
`;

const UPDATE_LOCATION_FAVORITE = gql`
mutation update_location_favorite($id: bigint!, $fav: Boolean!) {
  update_locations(where: {id: {_eq: $id}}, _set: {favorite: $fav}) {
    affected_rows
    returning {
      id
      name
      favorite
    }
  }
}
`;

////////// EXPORT //////////
export default withRouter(Locations);