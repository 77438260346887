import React from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';

const placeholder = require('../../../static/vehicle-placeholder.jpg');

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

////////// COMPONENT //////////
export default function ConciergeDetailsInfo(props) {
  const cls = useStyles();
  const { move, loaner, tookan } = props;

  // Get driver initials for placeholder driver image
  const getDriverInitials = () => {
    let fullName = move.driver_name ? move.driver_name : `N/A`
    if (fullName !== `N/A`) {
      let names = fullName.split(` `);
      let firstI = names[0][0];
      let secondI = names[names.length - 1][0];
      return `${firstI}${secondI}`.toUpperCase();
    }
    else return fullName;
  }

  // Get title & vehicle name based on if using a lyft, loaner, or consumer move
  const getVehicleTxt = () => {
    if (loaner) return `Loaner\xa0Vehicle:`;
    else return `Arrival\xa0Vehicle:`;
  }
  const getVehicle = (myMove) => {
    if (myMove) {
      let vehicle = ``;
      if (!myMove.vehicle_make && !myMove.vehicle_model) vehicle = `No Vehicle Info`;
      else vehicle = `${myMove.vehicle_year} ${myMove.vehicle_make} ${myMove.vehicle_model}`;

      let vehicleColor = ``;
      if (myMove.vehicle_color) vehicleColor = ` (${capFirst(myMove.vehicle_color)})`;

      return vehicle + vehicleColor;
    }
  }

  // Get the vehicle image & tooltip text based on move status
  const getVehicleImageTxt = () => {
    if (loaner && (!move.status || !move.status.includes(`delivery`))) return getVehicle(loaner);
    else return getVehicle(move);
  }
  const getVehicleImage = () => {
    if (tookan && tookan.loanerPickupImage && loaner && (!move.status || !move.status.includes(`delivery`))) return tookan.loanerPickupImage;
    else if (tookan && tookan.consumerPickupImage) return tookan.consumerPickupImage;
    else return placeholder;
  }

  // Get the displayed status based on the loaner move on consumer move
  const getStatus = () => {
    if (loaner && loaner.status && loaner.status === `pickup arrived`) return `Driver is picking up your loaner vehicle`;

    if (move.cancel_status && move.cancel_status !== ``) {
      if (move.cancel_status === `canceled`) return `Cancelled`;
      else if (move.cancel_status === `pending`) return `Cancel Pending`;
      else if (move.cancel_status === `seen`) return `Cancel Seen`;
      else if (move.cancel_status === `started`) return `Cancel Started`;
      else return `Waiting`;
    }
    else if (move.status && move.status !== ``) {
      if (move.status === `pickup started`) return `Driver is en route to your location`;
      else if (move.status === `pickup arrived`) return `Driver has arrived at your location`;
      else if (move.status === `pickup successful`) return `Driver has picked up your vehicle`;
      else if (move.status === `delivery started`) return `Vehicle is en route to service location`;
      else if (move.status === `delivery arrived`) return `Vehicle has arrived at service location`;
      else if (move.status === `delivery successful`) return `Vehicle has been delivered successfully`;
      else if (move.status === `canceled`) return `Cancelled`;
      else if (move.status === `failed`) return `Failed`;
      else return `Waiting`;
    }
    else return `Waiting`;
  }

  // Get title & expected time based on the move status
  const getTimeTxt = () => {
    if (move.status && move.status.includes(`delivery`)) return `Expected\xa0Delivery\xa0Time:`;
    else return `Expected\xa0Pickup\xa0Time:`;
  }
  const getTime = () => {
    if (move.pickup_time) return moment(move.pickup_time).format(`LT`);
    else return `Unknown`;
  }

  return (<>
    <div className={cls.main}>

      <div className={cls.info}>
        <Typography className={cls.infoTxt}>VEHICLE</Typography>
        <div className={cls.smallBreak} />
        <Tooltip title={getVehicleImageTxt()}>
          <img className={cls.infoImage} src={getVehicleImage()} alt="vehicle" />
        </Tooltip>
      </div>

      <div className={cls.paper}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>Information</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>{moment(move.pickup_time || ``).format(`MM/DD/YYYY`)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>{move.id}</Typography>
          </Grid>
        </Grid>

        <div className={cls.break} />

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Your&nbsp;Vehicle:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getVehicle(move)}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>{getVehicleTxt()}&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getVehicle(loaner)}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>{getTimeTxt()}&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getTime()}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Current&nbsp;Status:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getStatus()}</Typography>
            </div>
          </Grid>

          <div className={cls.hiddenLineBreak} />

          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.driver_name || `HopDriver`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Dealership:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.customer.name || `Unknown Dealer`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Your&nbsp;Name:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.consumer_name || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Your&nbsp;Phone&nbsp;#:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.consumer_phone || `-`}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={cls.info}>
        <Typography className={cls.infoTxt}>DRIVER</Typography>
        <div className={cls.smallBreak} />
        <Tooltip title={move.driver_name ? move.driver_name : `HopDriver`}>
          {tookan && tookan.driver && tookan.driver.fleet_thumb_image ?
            <img className={cls.infoImage} src={tookan.driver.fleet_thumb_image} alt="driver" /> :
            <div className={cls.infoImage}>
              <Typography className={cls.infoNoDriverTxt}>{getDriverInitials()}</Typography>
            </div>
          }
        </Tooltip>
      </div>

    </div >
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  info: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  infoImage: {
    objectFit: 'cover',
    display: 'block',
    position: 'relative',
    width: '128px',
    height: '128px',
    border: `1px solid #cdd6e5`,
    borderRadius: '50%',
    background: `#cdd6e5`,
    verticalAlign: 'top',
  },
  infoNoVehicleTxt: {
    position: 'absolute',
    top: '42%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 700,
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 700,
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: `default`,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));