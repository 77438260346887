import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Grid, Typography, IconButton, Icon } from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from "graphql-tag";
import { Subscription } from "react-apollo";
import fragments from '../utils/graphQL/fragments';
import * as Sentry from "@sentry/react";

import Loading from '../utils/Loading';

import LocationDetailsInfo from './locationDetails/LocationDetailsInfo';
import LocationDetailsEdit from './locationDetails/LocationDetailsEdit';
import LocationDetailsMap from './locationDetails/LocationDetailsMap';

const log = false;

////////// COMPONENT //////////
function LocationDetails(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const locationId = props.match.params.id;

  const goBack = () => {
    props.history.goBack();
  }

  return (<>
    <div className={cls.root}>
      <Container maxWidth="lg">
        <IconButton className={cls.back} onClick={() => goBack()}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Typography className={cls.head}>Location Details</Typography>
        { ctx && ctx.userIsAuthenticated() && (
          <Subscription
            subscription={GET_LOCATION}
            variables={{ locationId: locationId, customerId: parseInt(ctx.customerOverride || ctx.userProfile["https://hasura.io/jwt/claims"]['x-hasura-customer-id']) }}
            onError={(error) => {console.error(error); Sentry.captureException(error)}}>
            {({ loading, data }) => {
              if (loading) return <Loading fixed />
              if (data && data.locations && data.locations.length > 0) {
                const location = data.locations[0];
                log && console.log(`Location Details:`, location);

                return (<>
                  <LocationDetailsInfo location={location} />

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Divide spacer tip="Edit the details of this location.">Edit Location</Divide>
                      <LocationDetailsEdit location={location} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Divide spacer tip="View this location on Google maps.">View Location</Divide>
                      <LocationDetailsMap location={location} />
                    </Grid>
                  </Grid>
                </>)
              }
              else return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO LOCATION RECORD FOUND</Typography>
                </div>
              )
            }}
          </Subscription>)
        }
      </Container>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  back: {
    verticalAlign: 'top',
    display: 'inline-block',
    pading: theme.spacing(0.5),
    marginTop: '-12px',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  head: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// GRAPHQL //////////
const GET_LOCATION = gql`
subscription get_locations($locationId: bigint!, $customerId: bigint!) {
  locations(where: {id: {_eq: $locationId}, customer_id: {_eq: $customerId}}) {
    ...Location
  }
}
${fragments.location}
`;

////////// EXPORT //////////
export default withRouter(LocationDetails);