import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { GlobalContext } from '../../../global-context';

import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { makeStyles, Grid, Typography, TextField, MenuItem, Button, Icon } from '@material-ui/core';

import Loading from '../../utils/Loading';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function AppraisalDetailsCarousel(props) {
  const ctx = React.useContext(GlobalContext);
  const cls = useStyles();

  const { appraisal, tookanImages, setTookanImages } = props;
  let usableMoves = [];
  if (appraisal && appraisal.moves && appraisal.moves.length > 0)
    usableMoves = appraisal.moves.filter(move => move.pickup_stop_id || move.delivery_stop_id);

  const [selectedMove, setSelectedMove] = React.useState(usableMoves.length > 0 ? usableMoves[0].id : null);

  const [tookanLoading, setTookanLoading] = React.useState(true);
  const [galleryPics, setGalleryPics] = React.useState([]);
  const [lightboxPics, setLightboxPics] = React.useState([]);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [picIndex, setPicIndex] = React.useState(0);
  const [imageFilter, setImageFilter] = React.useState(null);

  const galleryRef = React.useRef(null);

  React.useEffect(() => {
    handleFilterChange();
  }, [tookanImages]);

  // Get Tookan data based on envs
  const GET_TOOKAN_DATA = async (api_key, job_id) =>
    axios.post(`${process.env.REACT_APP_BASEURL_TOOKAN}/get_task_details`, { api_key: api_key, job_id: job_id });

  // HARDCODED TEMP STUFF
  // const GET_TOOKAN_DATA = async (api_key, job_id) =>
  //   axios.post(`https://dispatch-api-prod.socialautotransport.com/v2/get_task_details`, {
  //     api_key: `53373bd6af460d0357112b3e1414234f1be0c0f92fdf`,
  //     job_id: job_id,
  //   });

  // Get related move Tookan images
  const getTookanImages = async move => {
    let pickupJobId = move ? move.pickup_stop_id : null;
    let deliveryJobId = move ? move.delivery_stop_id : null;
    let images = [];

    // If delivery exists, get the most recent vehicle images
    if (deliveryJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], deliveryJobId)
        .then(res => {
          log && console.log(`Tookan DELIVERY Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let taskHistory = res.data.data[0].task_history.map(item => item.description);
            taskHistory = taskHistory.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (taskHistory && taskHistory.length > 0) {
              let labels = taskHistory.map(obj => {
                if (obj.includes(`label`)) return JSON.parse(obj).label;
                else return obj;
              });
              let fleetData = taskHistory.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (fleetData && fleetData.length > 0) {
                for (let i = 0; i < fleetData.length; i++) {
                  if (fleetData[i].includes(`task_images`)) images.push({ label: labels[i], src: fleetData[i] });
                }
              }
            }
          }
        })
        .catch(err => {
          console.error(`Error retrieving Tookan DELIVERY images:`, err);
        });
    }

    // If no delivery images exist, get the pickup images
    if (images.length === 0 && pickupJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], pickupJobId)
        .then(res => {
          log && console.log(`Tookan PICKUP Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let taskHistory = res.data.data[0].task_history.map(item => item.description);
            taskHistory = taskHistory.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (taskHistory && taskHistory.length > 0) {
              let labels = taskHistory.map(obj => {
                if (obj.includes(`label`)) return JSON.parse(obj).label;
                else return obj;
              });
              let fleetData = taskHistory.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (fleetData && fleetData.length > 0) {
                for (let i = 0; i < fleetData.length; i++) {
                  if (fleetData[i].includes(`task_images`)) images.push({ label: labels[i], src: fleetData[i] });
                }
              }
            }
          }
        })
        .catch(err => {
          console.error(`Error retrieving Tookan PICKUP images:`, err);
        });
    }

    // Sort the images before setting them (and add an index field)
    images = sortImages(images);
    images = images.map((image, i) => ({
      label: image.label.replaceAll(`_`, ` `),
      original: image.src,
      thumbnail: image.src,
      index: i,
    }));
    log && console.log(`Tookan IMAGES:`, images);

    // Set final Tookan state objects
    setTookanImages(images);
    setTookanLoading(false);
  };

  React.useEffect(() => {
    if (tookanLoading) getTookanImages(usableMoves.length > 0 ? usableMoves[0] : null);
    // if (tookanLoading)
    //   getTookanImages({
    //     id: 17265,
    //     pickup_stop_id: 20810,
    //     delivery_stop_id: 20811,
    //   });
  }, []);

  const sortImages = images => {
    const imageOrder = [
      `Driver_Front`,
      `Driver_Front_Wheel`,
      `Passenger_Front`,
      `Passenger_Front_Wheel`,
      `Passenger_Rear`,
      `Passenger_Rear_Wheel`,
      `Driver_Rear`,
      `Driver_Rear_Wheel`,
      `Instrument_Cluster`,
      `Front_Interior`,
      `Interior_Rear`,
      `Trunk`,
    ];

    if (images && images.length > 0) {
      let newImages = images.map((image, i) => {
        let num = 100;
        if (imageOrder.includes(image.label)) num = imageOrder.indexOf(image.label);
        return { ...image, order: num };
      });

      return newImages.sort((a, b) => {
        const aNum = a.order;
        const bNum = b.order;
        if (aNum > bNum) return 1;
        if (aNum < bNum) return -1;
        return 0;
      });
    } else return [];
  };

  const handleSelectedMoveChange = event => {
    const moveId = event.target.value;
    setSelectedMove(moveId);

    const move = usableMoves.find(usableMove => usableMove.id === moveId);
    getTookanImages(move);
  };

  const handleFilterChange = (str = null) => {
    let images = [...tookanImages];

    if (images && images.length > 0) {
      if (str === `exterior`)
        images = images.filter(image => image.label.includes(`Driver`) || image.label.includes(`Passenger`));
      if (str === `interior`)
        images = images.filter(image => !image.label.includes(`Driver`) && !image.label.includes(`Passenger`));
      if (str === `wheel`) images = images.filter(image => image.label.includes(`Wheel`));
      if (str === `damage`) images = images.filter(image => image.label.includes(`Damage`));

      setPicIndex(0);
      setGalleryPics(images);
      setLightboxPics(images.map(image => image.original));
      setImageFilter(str);
    }
  };

  const handleFullscreen = event => {
    let currentIndex = 0;
    if (galleryRef) currentIndex = galleryRef.current.state.currentIndex;
    if (lightboxPics && lightboxPics.length > 0) {
      setPicIndex(currentIndex);
      setLightboxOpen(!lightboxOpen);
    }
  };

  return (
    <>
      <div className={cls.paper}>
        {tookanLoading ? (
          <Loading relative />
        ) : (
          <>
            <TextField
              select
              fullWidth
              label='Select Move Images'
              variant='outlined'
              size='small'
              value={selectedMove}
              onChange={handleSelectedMoveChange}
            >
              {usableMoves &&
                usableMoves.length > 0 &&
                usableMoves.map(move => (
                  <MenuItem key={`move-select-${move.id}`} value={move.id}>
                    #{move.id} - {moment(move.pickup_time).format(`MM/DD/YYYY`)} - {move.lane.description}
                  </MenuItem>
                ))}
            </TextField>

            <div className={cls.xsBreak} />

            <Grid container spacing={1} justify='space-between'>
              <Grid item xs>
                <Button
                  className={!imageFilter ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange()}
                >
                  Virtual&nbsp;Walkaround
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={imageFilter === `exterior` ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange(`exterior`)}
                >
                  Exterior{ctx.width === `xl` ? ` Images` : ``}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={imageFilter === `interior` ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange(`interior`)}
                >
                  Interior{ctx.width === `xl` ? ` Images` : ``}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={imageFilter === `wheel` ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange(`wheel`)}
                >
                  Wheel{ctx.width === `xl` ? ` Images` : ``}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={imageFilter === `damage` ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange(`damage`)}
                >
                  Damage{ctx.width === `xl` ? ` Images` : ``}
                </Button>
              </Grid>
            </Grid>

            <div className={cls.mdBreak} />

            {galleryPics.length > 0 ? (
              <div className={cls.carousel}>
                <Gallery
                  ref={galleryRef}
                  items={galleryPics}
                  thumbnailPosition='left'
                  lazyLoad
                  showIndex
                  disableKeyDown
                  startIndex={picIndex}
                  slideDuration={250}
                  showFullscreenButton={false}
                  onClick={handleFullscreen}
                />
                <Icon className={cls.fullscreenIcon} onClick={handleFullscreen}>
                  fullscreen
                </Icon>
              </div>
            ) : (
              <>
                <Typography className={cls.notFoundTxt}>NO IMAGES FOUND</Typography>
              </>
            )}

            {lightboxOpen && lightboxPics.length > 0 ? (
              <Lightbox
                mainSrc={lightboxPics[picIndex]}
                nextSrc={lightboxPics[(picIndex + 1) % lightboxPics.length]}
                prevSrc={lightboxPics[(picIndex + lightboxPics.length - 1) % lightboxPics.length]}
                onCloseRequest={() => setLightboxOpen(false)}
                onMovePrevRequest={() => setPicIndex((picIndex + lightboxPics.length - 1) % lightboxPics.length)}
                onMoveNextRequest={() => setPicIndex((picIndex + 1) % lightboxPics.length)}
                reactModalStyle={{
                  overlay: {
                    zIndex: 1000000,
                  },
                  content: {
                    zIndex: 1000000,
                  },
                }}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.paperRadius,
    background: '#fff',
    boxShadow: '0 0 12px #00000032',
  },
  carousel: {
    position: 'relative',
  },
  fullscreenIcon: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    color: '#fff',
    filter: 'drop-shadow(2px 2px 2px #00000080)',
    fontSize: 48,
    '&:hover, &:active': {
      color: theme.palette.info.main,
      transform: 'scale(1.1)',
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  button: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    background: 'transparent',
    boxShadow: 'none',
    '&:hover, &:active': {
      background: `${theme.palette.secondary.main}32`,
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  buttonActive: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  xsBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  smBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  mdBreak: {
    width: '100%',
    height: theme.spacing(2),
  },
  lgBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
}));
