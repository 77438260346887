import React, { useContext } from 'react';
import moment from 'moment';
import { GlobalContext } from "../../../global-context";

import { makeStyles, Grid, Typography } from '@material-ui/core';

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

////////// COMPONENT //////////
export default function ARInvoiceHeader(props) {
  const { theme, width } = useContext(GlobalContext);
  const cls = useStyles();

  const { selectedInvoice } = props;

  const milages = selectedInvoice.armoves.map(armove => armove.move.lane.distance_miles);
  const milage = (milages.length > 0) ? milages.reduce((total, currentValue) => total + currentValue) : 0;

  if (selectedInvoice && selectedInvoice !== null) {
    var { customer, start_datetime, end_datetime, armoves, status } = selectedInvoice;
  }

  return (<>
    {selectedInvoice && selectedInvoice !== null &&
      <div className={cls.paper}>
        <Grid container spacing={width === `xs` ? 2 : (width === `sm` ? 4 : 6)}>
          <Grid item sm={4} xs={12}>
            <Typography variant="subtitle2" className={cls.headTxt}>Invoice&nbsp;Details</Typography>
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Invoice&nbsp;ID:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>#{selectedInvoice.id.toString().padStart(6, "0")}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Invoice&nbsp;Status:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography style={{ color: status === 'processing' ? theme.palette.error.main : theme.palette.text.primary, fontWeight: status === 'processing' ? 600 : 400 }} className={cls.val}>{capFirst(status)}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Total&nbsp;Moves:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{armoves.length > 1 || armoves.length < 1 ? armoves.length + ` Moves` : armoves.length + ` Move`}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Total&nbsp;Milage:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{Number(milage).toFixed(1)}&nbsp;mi</Typography></Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="subtitle2" className={cls.headTxt}>Billing&nbsp;Details</Typography>
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Frequency:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{capFirst(customer.billing_frequency)}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Start&nbsp;Date:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{moment.utc(moment(start_datetime)).format('MM/DD/YYYY')}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>End&nbsp;Date:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{moment.utc(moment(end_datetime)).format('MM/DD/YYYY')}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Due&nbsp;Date:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{moment.utc(moment(end_datetime).add(customer.payment_terms, 'days')).format('MM/DD/YYYY')}</Typography></Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="subtitle2" className={cls.headTxt}>Customer&nbsp;Details</Typography>
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Dealer:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{customer.name}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Auto&nbsp;Pay:&nbsp;</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{customer.auto_pay ? `Activated` : `Not Activated`}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.block}>
              <Grid container spacing={0}>
                <Grid item xs={4}><Typography className={cls.key}>Notify:</Typography></Grid>
                <Grid item xs={8}><Typography className={cls.val}>{customer.notify_billing ? `Activated` : `Not Activated`}</Typography></Grid>
              </Grid>
            </div>
            {process.env.REACT_APP_ENV !== `production` && <>
              <div className={cls.lineBreak} />
              <div className={cls.block}>
                <Grid container spacing={0}>
                  <Grid item xs={4}><Typography className={cls.key}>Width:</Typography></Grid>
                  <Grid item xs={8}><Typography className={cls.val}>{width}</Typography></Grid>
                </Grid>
              </div>
            </>}
          </Grid>
        </Grid>
      </div>
    }
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px',
    marginBottom: theme.spacing(2),
    background: theme.palette.background.main,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  headTxt: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.25),
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      fontSize: '12px',
    },
  },
  key: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  val: {
    color: theme.palette.text.primary,
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  block: {
    display: 'block',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '5px 0',
    background: theme.palette.border,
  },
}));