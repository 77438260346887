import React, { useState, useEffect, useContext } from 'react';
import { Grid, DialogTitle, DialogContent, DialogContentText, Dialog, DialogActions, Button, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { GlobalContext } from "../../../global-context";
import moment from 'moment';
import helpers from '../../utils/helpers';

export default function InvoicePDFModal(props) {

  const ctx = useContext(GlobalContext);

  const [selection, setSelection] = useState([]);

  useEffect(() => {
    try {
      setSelection([...props.data.subselection].map(o => Object.assign({}, o, { downloaded: false, invoice: { ...props.data.invoice } })))
    } catch (err) {
      console.error(err)
      setSelection([])
    }
  }, [])

  async function handleDownloadInvoice(armove) {
    let currentInvoice = Object.assign({}, { ...props.data.invoice }, { armoves: [armove] })
    try {
      helpers.createSingleMoveInvoice(currentInvoice)
    } catch (err) {
      ctx.handleNotifications(true, 'error', "Error while generating PDF")
      return;
    }
    setSelection([...selection].map(o => {
      if (o.id === armove.id) return Object.assign(o, { downloaded: true })
      else return o
    }))
  }

  return (
    <Dialog
      open={props.open}
      aria-labelledby="invoice-pdf-modal"
    >
      <DialogTitle id="invoice-pdf-modal">Invoice PDF Download</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Due to the volume of selected moves, please download the invoices individually below.
        </DialogContentText>
        <br />
      </DialogContent>
      <br />
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Table>
            <TableBody>
              {selection.map((armove) => (
                <TableRow key={armove.id}>
                  <TableCell component="th" scope="row">
                    {armove.move.id}
                  </TableCell>
                  <TableCell align="right">{moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`)}</TableCell>
                  <TableCell align="right">{armove.move.consumer_name ? armove.move.consumer_name : armove.move.vehicle_stock}</TableCell>
                  <TableCell align="right">${armove.due_amount.toFixed(2)}</TableCell>
                  <TableCell align="right">
                    <Button disabled={armove.downloaded} variant="contained" size="small" onClick={() => handleDownloadInvoice(armove)} color="primary">
                      {armove.downloaded ? "Downloaded" : "Download"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose} color="default">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
};