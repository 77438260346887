import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Grid, Typography, IconButton, Icon, Button, Tooltip, Menu, MenuItem } from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from "graphql-tag";
import { Subscription } from "react-apollo";
import * as Sentry from "@sentry/react";

import Loading from '../utils/Loading';
import MoveStatusTracker from '../reusable/MoveStatusTracker';
import MoveDetailsInfo from './moveDetails/MoveDetailsInfo';
import MoveDetailsLocation from './moveDetails/MoveDetailsLocation';
import MoveDetailsTracking from './moveDetails/MoveDetailsTracking';
import MoveCancelModal from './moves/MoveCancelModal';
import helpers from '../utils/helpers';

const log = false;

////////// COMPONENT //////////
function MoveDetails(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const moveId = props.match.params.id;

  const [tookan, setTookan] = useState({ loading: true, pickup: { images: [], signature: null, name: null }, delivery: { images: [], signature: null, name: null }, driver: null });
  const [actionsOpen, setActionsOpen] = useState(null);
  const [modal, setModal] = useState({ open: false });

  const GET_TOOKAN_DATA = async (api_key, job_id) => axios.post(`${process.env.REACT_APP_BASEURL_TOOKAN}/get_task_details`, { api_key: api_key, job_id: job_id });
  const GET_TOOKAN_DRIVERS = async (api_key) => axios.post(`${process.env.REACT_APP_BASEURL_TOOKAN}/get_available_agents`, { api_key: api_key });

  const getTookanData = async (move) => {
    let pickupJobId = move ? move.pickup_stop_id : null;
    let deliveryJobId = move ? move.delivery_stop_id : null;
    let pickupData = { images: [], signature: null, name: null };
    let pickupImage = null;
    let deliveryData = { images: [], signature: null, name: null };
    let deliveryImage = null;
    let driver = null;

    // Tookan PICKUP images and signature
    if (pickupJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], pickupJobId)
        .then(res => {
          // log && console.log(`TOOKAN Pickup Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else if (item.includes(`acknowledgement_images`)) return true;
              else if (item.includes(`SignerName`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) pickupImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                extractedData = extractedData.sort((a, b) => {
                  if (!a.includes(`task_images`) && !a.includes(`acknowledgement_images`)) return 1;
                  if (!b.includes(`task_images`) && !b.includes(`acknowledgement_images`)) return -1;
                  else if (a.includes(`acknowledgement_images`)) return 1;
                  else if (b.includes(`acknowledgement_images`)) return -1;
                  else return 0;
                });

                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`) || extractedData[i].includes(`acknowledgement_images`)) pickupData.images.push(extractedData[i]);
                  if (extractedData[i].includes(`acknowledgement_images`)) pickupData.signature = extractedData[i];
                  if (!extractedData[i].includes(`task_images`) && !extractedData[i].includes(`acknowledgement_images`)) pickupData.name = extractedData[i];
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Failed to retrieve Tookan PICKUP data:`, err);
          ctx.handleNotifications(true, `error`, `Error retrieving Tookan pickup data: ` + err.toString());
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan DELIVERY images and signature
    if (deliveryJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], deliveryJobId)
        .then(res => {
          // log && console.log(`TOOKAN Delivery Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else if (item.includes(`acknowledgement_images`)) return true;
              else if (item.includes(`SignerName`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) deliveryImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                extractedData = extractedData.sort((a, b) => {
                  if (!a.includes(`task_images`) && !a.includes(`acknowledgement_images`)) return 1;
                  if (!b.includes(`task_images`) && !b.includes(`acknowledgement_images`)) return -1;
                  else if (a.includes(`acknowledgement_images`)) return 1;
                  else if (b.includes(`acknowledgement_images`)) return -1;
                  else return 0;
                });

                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`) || extractedData[i].includes(`acknowledgement_images`)) deliveryData.images.push(extractedData[i]);
                  if (extractedData[i].includes(`acknowledgement_images`)) deliveryData.signature = extractedData[i];
                  if (!extractedData[i].includes(`task_images`) && !extractedData[i].includes(`acknowledgement_images`)) deliveryData.name = extractedData[i];
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Error retrieving Tookan DELIVERY data:`, err);
          ctx.handleNotifications(true, `error`, `Error retrieving Tookan delivery data: ` + err.toString());
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan DRIVER data
    await GET_TOOKAN_DRIVERS(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`])
      .then(res => {
        // log && console.log(`TOOKAN Driver Response:`, res);
        if (res.status === 200 && res.data.data.length > 0) {
          let tookanDrivers = res.data.data.filter(driver => driver.fleet_id === move.driver_id);
          if (tookanDrivers.length > 0) driver = tookanDrivers[0];
        }
      })
      .catch(err => {
        console.log(`Failed to retrieve Tookan drivers:`, err);
        setTookan({ ...tookan, loading: false });
      });

    // Set Tookan state object
    setTookan({
      loading: false,
      pickup: pickupData,
      pickupImage: pickupImage,
      delivery: deliveryData,
      deliveryImage: deliveryImage,
      driver: driver
    });
  }

  const fetchTookan = (move) => {
    getTookanData(move);
    if (move.status && move.cancel_status === null && move.status !== `delivery successful` && (move.status.includes(`pickup`) || move.status.includes(`delivery`))) {
      setInterval(() => getTookanData(move), 60000);
    }
  };

  const handleActionsOpen = (event) => {
    setActionsOpen(event.currentTarget);
  }
  const handleActionsClose = (event) => {
    setActionsOpen(null);
  }
  const handleAction = (action) => {
    handleActionsClose();
    if (action.handler) action.handler();
  }

  const goBack = () => {
    props.history.goBack();
  }
  const goToConciergeDetails = (moveId) => {
    props.history.push(`/concierge/${moveId}`);
  }

  const handleModalOpen = (input = null) => {
    setModal({ ...modal, open: true, input: input });
  }
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  }

  function handleMoveInvoice(move) {
    let invoice = null;
    try {
      invoice = move.accountsReceivable.invoice;
    } catch (err) {
      console.error("Failed to parse move invoice:", err)
    }
    if (invoice) helpers.createSingleMoveInvoice(invoice)
    else ctx.handleNotifications(true, 'warning', 'Could not generate invoice PDF. Please refer to the Invoices page for this move.')
  }

  function returnMoveToInverse(move) {
    return { moveToInverse: move }
  }

  let move = null;

  return (<>
    <div className={cls.root}>
      <Container maxWidth="lg">
        <IconButton style={{ marginRight: ctx.theme.spacing(1) }} className={cls.iconBtn} onClick={() => goBack()}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Typography className={cls.head}>Move Details</Typography>
        { ctx && ctx.userIsAuthenticated() && (
          <Subscription
            subscription={GET_MOVE}
            variables={{ moveId: moveId, customerId: parseInt(ctx.customerOverride || ctx.userProfile["https://hasura.io/jwt/claims"]['x-hasura-customer-id']) }}
            onError={(error) => {console.error(error); Sentry.captureException(error)}}>
            {({ loading, data }) => {
              if (loading) return <Loading fixed />
              if (data && data.moves && data.moves.length > 0) {
                move = data.moves[0];
                log && console.log(`Move Details:`, move);

                const globalActions = [
                  { label: `View Consumer Record`, handler: () => goToConciergeDetails(move.id), hide: !move.consumer_name },
                  { label: `Cancel Move`, handler: () => handleModalOpen(move), hide: move.cancel_status || move.status === `delivery successful` },
                  { label: `Download Invoice`, handler: () => handleMoveInvoice(move), hide: ['delivery successful', 'canceled', 'cancelled'].indexOf(move.status) < 0 },
                ]

                // Get images and driver data from Tookan (Set interval to fetch)
                if (tookan.loading) {
                  fetchTookan(move);
                }

                return (<>
                  <MoveCancelModal open={modal.open} onClose={handleModalClose} moveInput={modal.input} />

                  <div style={{ float: 'right' }}>
                    <Button variant="outlined" color="default" className={cls.inverseBtn} onClick={() => props.history.push('/moves/add', returnMoveToInverse(move))}>Create Inverse</Button>

                    <Tooltip placement="top" title={`Actions`}>
                      <IconButton style={{ marginLeft: ctx.theme.spacing(1), color: ctx.theme.palette.secondary.main }} className={cls.iconBtn} onClick={handleActionsOpen}>
                        <Icon>settings</Icon>
                      </IconButton>
                    </Tooltip>

                    <Menu keepMounted id={`move-actions-menu`} anchorEl={actionsOpen} open={Boolean(actionsOpen)} onClose={handleActionsClose}>
                      {globalActions.map((action, i) => !action.hide ? <MenuItem key={`move-action-${i}`} onClick={() => handleAction(action)}>{action.label || `Action ${i + 1}`}</MenuItem> : null)}
                    </Menu>
                  </div>

                  <div style={{ width: '100%', height: '8px' }} />
                  <MoveStatusTracker move={move} size="large" />
                  <div style={{ width: '100%', height: '24px' }} />
                  <MoveDetailsInfo move={move} tookan={tookan} />

                  <Divide spacer tip="View the pickup & delivery this move is associated with.">Pickup & Delivery</Divide>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <MoveDetailsLocation type="pickup" move={move} tookan={tookan.pickup} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <MoveDetailsLocation type="delivery" move={move} tookan={tookan.delivery} />
                    </Grid>
                  </Grid>

                  <Divide spacer tip="Track where the move is currently located">Tracking</Divide>
                  <MoveDetailsTracking move={move} />
                </>)
              }
              else return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO MOVE RECORD FOUND</Typography>
                </div>
              )
            }}
          </Subscription>)
        }
      </Container>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  iconBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: '-12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  inverseBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
      color: '#fff',
    },
    marginLeft: theme.spacing(1),
  },
  cancelBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    borderColor: theme.palette.error.light,
    color: theme.palette.error.main,
    "&:hover": {
      borderColor: theme.palette.error.main,
      background: theme.palette.error.main,
      color: '#fff',
    },
  },
  invoiceBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
      color: '#fff',
    },
  },
  head: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// GRAPHQL //////////
const GET_MOVE = gql`
  subscription get_moves($moveId: bigint!, $customerId: bigint!) {
    moves(where: {id: {_eq: $moveId}, customer_id: {_eq: $customerId}}) {
      id
      status
      cancel_status
      tags
      driver_id
      driver_name
      customer {
        id
        name
      }
      pickup_stop_id
      delivery_stop_id
      lane {
        id
        description
        distance_miles
        pickup {
          id
          name
          address
          nickname
        }
        delivery {
          id
          name
          address
          nickname
        }
      }
      ready_by
      deliver_by
      pickup_time
      pickup_started
      pickup_arrived
      pickup_successful
      delivery_time
      delivery_started
      delivery_arrived
      delivery_successful
      tracking_link
      reference_num
      manual_flag
      vehicle_stock
      vehicle_vin
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
      move_details
      consumer_pickup
      consumer_name
      consumer_phone
      consumer_at_pickup
      consumer_type
      dealer_contact
      move_type
      moveByReturnRideId {
        move_type
        id
        consumer_pickup
        consumer_name
        consumer_phone
        consumer_at_pickup
        consumer_type
        dealer_contact
        tags
        reference_num
        manual_flag
        vehicle_stock
        vehicle_vin
        vehicle_make
        vehicle_model
        vehicle_year
        vehicle_color
      }
      parent_move {
        move_type
        id
        consumer_pickup
        consumer_name
        consumer_phone
        consumer_at_pickup
        consumer_type
        dealer_contact
        tags
        reference_num
        manual_flag
        vehicle_stock
        vehicle_vin
        vehicle_make
        vehicle_model
        vehicle_year
        vehicle_color
      }
      accountsReceivable {
        invoice {
          id
          accounting_num
          start_datetime
          end_datetime
          status
          customer {
            id
            name
            address
            billing_frequency
            payment_terms
            auto_pay
            notify_billing
            paymentmethods(where: {type: {_eq: "manual"}}) {
              id
            }
          }
          arpayments(order_by: {createdat: asc}) {
            id
            amount
            status
            accounting_id
            gateway_transaction_id
            createdat
          }
          armoves(where: {active: {_eq: 1}, type: {_eq: "move"}, move_id: {_eq: $moveId}}, order_by: {move_id: asc}) {
            id
            active
            type
            move_id
            invoice_id
            arevent_id
            billable_datetime
            discount_amount
            discount_reason
            disputed
            dispute_reason
            due_amount
            paid_amount
            status
            notes
            author
            accounting_item_id
            invoice {
              id
              accounting_num
              customer {
                id
                accounting_id
              }
            }
            move {
              id
              consumer_name
              class
              chargeable
              customer_id
              delivery_stop_id
              status
              delivery_arrived
              delivery_started
              delivery_successful
              pickup_arrived
              pickup_started
              pickup_successful
              vehicle_color
              vehicle_make
              vehicle_model
              vehicle_odometer
              vehicle_stock
              vehicle_vin
              vehicle_year
              reference_num
              lane {
                id
                description
                distance_miles
                dealer_base_discount
                dealer_stranded_discount
                tolls
                delivery {
                  id
                  address
                  name
                }
                pickup {
                  id
                  address
                  name
                }
              }
              raterule {
                id
                rate
                type
              }
              customer {
                id
                name
                address
                billing_frequency
                payment_terms
                auto_pay
              }
            }
            details {
              id
              name
              notes
              amount
            }
            revisions(order_by: {revision: desc}) {
              id
              revision
              due_amount
              discount_amount
              discount_reason
              disputed
              dispute_reason
              details {
                id
                name
                notes
                amount
              }
            }
          }
        }
      }
    }
  }
`;

////////// EXPORT //////////
export default withRouter(MoveDetails);